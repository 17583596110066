import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../app/rootReducer';

interface LoaderSlice {
  visible: boolean
}

const initialState: LoaderSlice = { visible: false };

export const loaderSlice = createSlice({
  name: 'boardFilters',
  initialState,
  reducers: {
    showLoader: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.visible = true;
    },
    hideLoader: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.visible = false;
    },
  },
});

export const { showLoader, hideLoader } = loaderSlice.actions;

export const selectLoaderState = (state: RootState) => state.loaderReducer;

export default loaderSlice.reducer;
