import { APIAgroanalisaService } from '.';
import { Index } from '../@types/index_';

class Indexes {
  private baseUrl = 'v1/indexes';

  async findOneById(id: string) {
    const { data } = await APIAgroanalisaService.get<Index>(`${this.baseUrl}/${id}`);
    return data;
  }

  async find(q: string) {
    const { data } = await APIAgroanalisaService.get<Index[]>(this.baseUrl, { params: { q } });
    return data;
  }

  async create(index: IndexDataInsert) {
    const { data } = await APIAgroanalisaService.post<Index>(this.baseUrl, { index });
    return data;
  }

  async update(id: string, index: IndexDataInsert) {
    const { data } = await APIAgroanalisaService.patch<Index>(`${this.baseUrl}/${id}`,
      { index });
    return data;
  }

  remove(id: string) {
    return APIAgroanalisaService.delete(`${this.baseUrl}/${id}`);
  }
}

type IndexDataInsert = Omit<Index, 'id' | 'uuid' | 'type'>

export default new Indexes();
