import { ChartType } from '@cubejs-client/react';
import React, { useCallback } from 'react';
import { Dropdown } from '@agro1desenvolvimento/react-components';

const ChartTypes = [
  {
    name: 'line',
    title: 'Linha',
    icon: 'fa-chart-line',
  },
  {
    name: 'area',
    title: 'Área',
    icon: 'fa-chart-area',
  },
  {
    name: 'bar',
    title: 'Barra',
    icon: 'fa-chart-bar',
  },
  {
    name: 'pie',
    title: 'Pizza',
    icon: 'fa-chart-pie',
  },
  {
    name: 'table',
    title: 'Tabela',
    icon: 'fa-table',
  },
  {
    name: 'number',
    title: 'Número',
    icon: 'fa-info',
  },
];

const SelectChartType1: React.FC<
  { updateChartType: ((chartType: ChartType) => void), chartType: ChartType | undefined }
> = ({ chartType, updateChartType }) => {
  const itemTemplate = useCallback((item) => (
    <>
      <span className="dropdown-align-center p-d-inline-block p-mr-1">
        <i className={`fas ${item.icon}`} />
      </span>
      {item.title}
    </>
  ), []);

  return (
    <div className="p-col select-chart-type">
      <Dropdown
        options={ChartTypes}
        onChange={({ value }) => updateChartType(value)}
        value={chartType}
        optionValue="name"
        optionLabel="title"
        className="p-m-1 dropdown"
        itemTemplate={itemTemplate}
        valueTemplate={itemTemplate}
      />
    </div>
  );
};

export default SelectChartType1;
