import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { Dropdown, InputNumber } from '@agro1desenvolvimento/react-components';
import { join, map } from 'lodash';
import { City } from '../../../@types/city';
import { Region } from '../../../@types/region';
import { hideLoader, showLoader } from '../../../components/Loader/loaderSlice';
import { MODAL_ANIMATION_DURATION } from '../../../utils';
import {
  NotificationsService,
  FarmsService,
  RegionsService,
  CitiesService,
} from '../../../services';
import { FloatingLabelInput } from '../../../components';
import { RouteParams } from '../index';

const CreateEditPage: React.FC<PropsTypes> = ({ onSave, onClose }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [area, setArea] = useState<number | undefined>();
  const [regions, setRegions] = useState<Region[]>([]);
  const [region, setRegion] = useState<Region>();
  const [city, setCity] = useState<City>();
  const [cities, setCities] = useState<City[]>([]);
  const routeParams = useParams<RouteParams>();
  const saveEnabled = !!name && !!area;

  const loadRegions = async () => {
    if (regions.length) return;

    try {
      setRegions(await RegionsService.find(''));
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao carregar regiões' });
    }
  };

  const loadCities = async () => {
    if (cities.length) return;

    try {
      setCities(await CitiesService.find(''));
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao carregar cidades' });
    }
  };

  const loadFarm = async () => {
    const { customerId, id } = routeParams;

    try {
      if (!id) {
        cleanFields();
        return;
      }

      if (!customerId) throw new Error('Não está presente o ID do cliente');

      const farm = await FarmsService.findOneById({ customerId, farmId: id });
      setName(farm.name);
      setArea(farm.area);
      setRegion(farm.region);
      setCity(farm.city);
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao carregar propriedade para edição' });
      onClose();
    }
  };

  const loadData = async () => {
    dispatch(showLoader());

    try {
      await Promise.all([loadFarm(), loadRegions(), loadCities()]);
    } finally {
      dispatch(hideLoader());
    }
  };

  const onClickSave = async () => {
    if (!name || !area) return;

    const farm = { name, area, cityUuid: city?.uuid, regionUuid: region?.uuid };
    const { customerId, id } = routeParams;

    if (!customerId) throw new Error('Não foi possível carregar o id do cliente');

    try {
      if (!id) {
        await FarmsService.create(customerId, farm);
      } else {
        await FarmsService.update({ customerId, farmId: id }, farm);
      }
      onSave();
      onClickCancel();
    } catch (error: any) {
      console.error(error);
      if (error.response.data) {
        const response = error?.response?.data;
        const errors = join(map(Object.keys(response), (key) => response[key]), ', ');

        const message = join(['Falha ao salvar propriedade:', errors], ' ');
        NotificationsService.error({ message });
      } else {
        NotificationsService.error({ message: 'Falha ao salvar propriedade' });
      }
    }
  };

  const cleanFields = () => {
    setTimeout(() => {
      setName('');
      setArea(undefined);
      setRegion(undefined);
      setCity(undefined);
    }, MODAL_ANIMATION_DURATION);
  };

  const onClickCancel = () => {
    onClose();
    cleanFields();
  };

  useEffect(
    () => { if (routeParams.action) loadData(); },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [routeParams.id],
  );

  return (
    <form
      className="create-edit-page create-edit-page-customer-farm"
      onSubmit={(e) => e.preventDefault()}
    >
      <FloatingLabelInput
        testId="name"
        label="Nome"
        onChange={(e) => setName(e.currentTarget.value)}
        value={name}
      />

      <span className="field p-float-label p-mr-0">
        <InputNumber
          id="area"
          data-testid="area"
          onChange={(e) => setArea(e.value)}
          value={area}
          className="number-input"
          maxFractionDigits={2}
          min={0}
        />
        <label htmlFor="area">Área contratada</label>
      </span>

      <Dropdown
        value={city}
        options={cities}
        onChange={(e) => setCity(e.value)}
        placeholder="Selecione uma cidade"
        filter
        showClear
        optionLabel="name"
        filterBy="name"
        className="dropdown-input"
        emptyFilterMessage="Nenhuma cidade encontrada"
      />

      <Dropdown
        value={region}
        options={regions}
        onChange={(e) => setRegion(e.value)}
        placeholder="Selecione uma região"
        filter
        showClear
        optionLabel="description"
        filterBy="description"
        className="dropdown-input"
        emptyFilterMessage="Nenhuma região encontrada"
      />

      <div className="field is-grouped is-grouped-right">
        <p className="control">
          <button
            type="button"
            className="button is-light"
            onClick={onClickCancel}
          >
            Cancelar
          </button>
        </p>
        <p className="control">
          <button
            type="submit"
            className="button is-link"
            onClick={onClickSave}
            disabled={!saveEnabled}
          >
            Salvar
          </button>
        </p>
      </div>
    </form>
  );
};

export default CreateEditPage;

type PropsTypes = {
  onClose: () => void;
  onSave: () => void;
}
