import React, { FC } from 'react';
import { Dropdown } from '@agro1desenvolvimento/react-components';
import { useDispatch } from 'react-redux';
import { Harvest } from '../../@types/harvest';
import { Region } from '../../@types/region';
import { City } from '../../@types/city';
import { Crop } from '../../@types/crop';

const Filter: FC<FilterType> = ({
  value,
  onChangeHandler,
  optionValue,
  optionLabel,
  filterBy,
  options,
  placeholder,
  emptyMessage,
}) => {
  const dispatch = useDispatch();
  return (
    <Dropdown
      className="p-col-12 p-p-0"
      value={value}
      onChange={(event) => dispatch(onChangeHandler(event.value))}
      options={options}
      optionValue={optionValue}
      optionLabel={optionLabel}
      filterBy={filterBy}
      filter
      showClear
      placeholder={placeholder}
      emptyMessage={emptyMessage}
      appendTo={document.body}
    />
  );
};

export type FilterType = {
  field?: string;
  label?: string;
  value?: string;
  onChangeHandler: (event: string) => void;
  optionValue: string;
  optionLabel: string;
  filterBy: string;
  options: Harvest[] | Region[] | City[] | Crop[];
  placeholder: string;
  emptyMessage: string;
};

export default Filter;
