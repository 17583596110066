import { Button, Menu } from '@agro1desenvolvimento/react-components';
import { filter } from 'lodash';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearCurrentState,
  fetchCities,
  fetchCrops,
  fetchHarvests,
  fetchRegions,
  selectBoardFiltersState,
  setCurrentCity,
  setCurrentCrop,
  setCurrentHarvest,
  setCurrentRegion,
} from './boardFiltersSlice';
import Filter, { FilterType } from './Filter';

const BoardFilters: FC = () => {
  const dispatch = useDispatch();
  const [visibleFilters, setVisibleFilters] = useState<string[]>([]);
  const menu = useRef<Menu>(null);
  const {
    harvests,
    regions,
    cities,
    crops,
    currentHarvest,
    currentRegion,
    currentCity,
    currentCrop,
  } = useSelector(selectBoardFiltersState);

  useEffect(() => {
    const visible = [];
    if (currentCity) visible.push('citiesId');
    if (currentCrop) visible.push('cropsId');
    if (currentHarvest) visible.push('harvestsId');
    if (currentRegion) visible.push('regionsId');
    setVisibleFilters(visible);
  }, [currentCity, currentCrop, currentHarvest, currentRegion]);

  const clearFilters = () => {
    dispatch(clearCurrentState());
    setVisibleFilters([]);
  };

  useEffect(() => {
    if (harvests.length === 0) dispatch(fetchHarvests());
    if (cities.length === 0) dispatch(fetchCities());
    if (regions.length === 0) dispatch(fetchRegions());
    if (crops.length === 0) dispatch(fetchCrops());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderFilter = (item: FilterType) => {
    if (item.field && !visibleFilters.includes(item.field)) return;

    return (
      <div className={visibleFilters.length > 1 ? 'p-col' : 'p-col-12 p-md-6'}>
        <Filter
          key={item.field}
          value={item.value}
          onChangeHandler={item.onChangeHandler}
          optionValue={item.optionValue}
          optionLabel={item.optionLabel}
          filterBy={item.filterBy}
          options={item.options}
          placeholder={item.placeholder}
          emptyMessage={item.emptyMessage}
        />
      </div>
    );
  };

  const filters = useMemo(() => [
    {
      field: 'harvestsId',
      label: 'Safras',
      value: currentHarvest,
      onChangeHandler: setCurrentHarvest,
      optionValue: 'id',
      optionLabel: 'description',
      filterBy: 'description',
      options: harvests,
      placeholder: 'Safra',
      emptyMessage: 'Nenhuma safra encontrada',
    },
    {
      field: 'regionsId',
      label: 'Regiões',
      value: currentRegion,
      onChangeHandler: setCurrentRegion,
      optionValue: 'id',
      optionLabel: 'description',
      filterBy: 'description',
      options: regions,
      placeholder: 'Região',
      emptyMessage: 'Nenhuma região encontrada',
    },
    {
      field: 'citiesId',
      label: 'Cidades',
      value: currentCity,
      onChangeHandler: setCurrentCity,
      optionValue: 'id',
      optionLabel: 'name',
      filterBy: 'name',
      options: cities,
      placeholder: 'Cidade',
      emptyMessage: 'Nenhuma cidade encontrada',
    },
    {
      field: 'cropsId',
      label: 'Atividades',
      value: currentCrop,
      onChangeHandler: setCurrentCrop,
      optionValue: 'id',
      optionLabel: 'description',
      filterBy: 'description',
      options: crops,
      placeholder: 'Atividades',
      emptyMessage: 'Nenhuma atividade encontrada',
    },
  ], [
    cities,
    crops,
    harvests,
    regions,
    currentCity,
    currentCrop,
    currentHarvest,
    currentRegion,
  ]);

  const buttons = useMemo(
    () => filters.map(({ field, label }) => ({
      label,
      icon: visibleFilters.includes(field) ? 'pi pi-check' : '',
      command: () => {
        setVisibleFilters((futureVisibleFilters) => {
          const found = futureVisibleFilters.includes(field);

          if (found) return filter(futureVisibleFilters, (item) => item !== field);
          return [...futureVisibleFilters, field];
        });
      },
    })),
    [visibleFilters, filters],
  );

  return (
    <div className="p-d-flex p-jc-center">
      <Menu model={buttons} popup ref={menu} appendTo={document.body} />
      <div className="filters-container ml-2 mr-2">
        <span className="p-d-flex p-jc-between">
          <Button
            label="Filtros"
            icon="pi pi-filter"
            data-test="addFilter"
            className="p-button-text"
            tooltip="Adicionar Filtros"
            onClick={(event) => menu.current?.toggle(event)}
            tooltipOptions={{ position: 'bottom' }}
          />
          { visibleFilters.length > 0 && (
            <Button
              label="Limpar filtros"
              icon="pi pi-times"
              className="p-button-text p-button-danger"
              data-test="clearFilter"
              tooltip="Limpar Filtros"
              onClick={clearFilters}
              tooltipOptions={{ position: 'bottom' }}
            />
          )}
        </span>
        <div className="filters p-grid">
          { filters.map((filterElement) => renderFilter(filterElement)) }
        </div>
      </div>
    </div>
  );
};

export default BoardFilters;
