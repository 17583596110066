// eslint-disable-next-line import/prefer-default-export
export const ENV = (() => {
  switch (window.location.hostname) {
    case 'agroanalisa-homologacao.agro1.inf.br':
      return 'homologation';
    default:
      return process.env.NODE_ENV;
  }
})();

export const MODAL_ANIMATION_DURATION = 400;

export const DAYJS_FORMATS = {
  american: 'YYYY-MM-DD',
  brazilian: 'DD/MM/YYYY',
  default: 'L LT',
  withoutTime: 'L',
};
