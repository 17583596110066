import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { dateBrazilianFormat, newDateWithoutUTC } from '../../utils';
import { hideLoader, showLoader } from '../../components/Loader/loaderSlice';
import { CreateEditModal, Notifications, TableDropdownButton } from '../../components';
import ListTable, { ColumnProps } from '../../components/ListTable';
import CreateEditPage from './CreateEditPage';
import { Customer } from '../../@types/customer';
import { useDataMatch } from '../../hooks';
import { CustomerRouterType } from '.';
import {
  NotificationsService,
  CustomersService,
} from '../../services';

const CustomersPage: React.FC<CustomerRouterType> = ({ match, history }) => {
  const dispatch = useDispatch();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [dropdownOpened, setDropdownOpened] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const isCreatingEditing = useDataMatch(match.params.action, ['novo', 'editar']);

  const deleteNotification = (id: string, description: string) => NotificationsService.custom({
    message: <Notifications.Button
      text={<>Você tem certeza que deseja excluir <b>{description}</b>?</>}
      onClickYes={() => onConfirmDelete(id)}
    />,
  });

  const onConfirmDelete = async (id: string) => {
    try {
      await CustomersService.remove(id);
      loadCustomers();
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao excluir Cliente' });
    }
  };

  const columns: ColumnProps<Customer>[] = [{
    field: 'name',
    header: 'Nome',
  },
  {
    field: 'city',
    header: 'Cidade',
    body: (value: Customer) => value.city.name,
  },
  {
    field: 'region',
    header: 'Região',
    body: (value: Customer) => value.region.description,
  },
  {
    field: 'currentContract',
    header: 'Área total',
    body: (value: Customer) => (
      value.currentContract ? Number(value.currentContract.area).toLocaleString() : ''
    ),
    className: 'small-column',
  },
  {
    field: 'currentContract',
    header: 'Contrato até',
    body: (value: Customer) => (
      value.currentContract
        ? dateBrazilianFormat(newDateWithoutUTC(value.currentContract.endDate)) : ''
    ),
    className: 'small-column',
  },
  {
    field: 'email',
    header: 'Email',
    className: 'large-column',
  },
  {
    header: '',
    body: ({ name, id, uuid }: Customer) => (
      <TableDropdownButton
        isActive={dropdownOpened === uuid}
        onClickOver={() => setDropdownOpened('')}
        onClickDropdownButton={() => setDropdownOpened(dropdownOpened ? '' : uuid)}
        redirectOnClickEdit={`/clientes/editar/${id}`}
        onClickDelete={() => deleteNotification(id, name)}
      >
        <Link className="proprieties-button" to={`/clientes/${id}/propriedades`}>
          <span className="icon">
            <FontAwesomeIcon icon="globe-americas" className="fa" />
          </span>
          Propriedades
        </Link>
        <Link className="contracts-button" to={`/clientes/${id}/contratos`}>
          <span className="icon">
            <FontAwesomeIcon icon="folder-open" className="fa" />
          </span>
          Contratos
        </Link>
      </TableDropdownButton>
    ),
    sortable: false,
    className: 'options-column',
  }];

  const loadCustomers = async () => {
    dispatch(showLoader());
    try {
      setCustomers(await CustomersService.find(''));
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao carregar Lista de Clientes' });
    } finally {
      dispatch(hideLoader());
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { loadCustomers(); }, []);

  useEffect(() => {
    setModalVisible(isCreatingEditing);
  }, [isCreatingEditing]);

  return (
    <div className="customers">
      <div className="page-title">
        <h1 className="title">Clientes</h1>
      </div>
      <div>
        <div className="buttons is-right">
          <p className="control">
            <Link className="button is-link" to="/clientes/novo">Adicionar Cliente</Link>
          </p>
        </div>
        <CreateEditModal
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
          onClosed={() => history.push('/clientes')}
          title={`${match.params.id ? 'Edição' : 'Criação'} de Clientes`}
        >
          <CreateEditPage
            id={match.params.id}
            onClose={() => setModalVisible(false)}
            onSave={() => loadCustomers()}
          />
        </CreateEditModal>
        <ListTable
          columns={columns}
          data={customers}
          showUUID
          alignUUID
        />
      </div>
    </div>
  );
};

export default CustomersPage;
