import React from 'react';
import { Switch, Route } from 'react-router';
import CsvPage from './CsvPage';

const IndexSummaryPage = () => (
  <Switch>
    <Route path="/resumo_indices/csv" component={CsvPage} />
  </Switch>
);

export default IndexSummaryPage;
