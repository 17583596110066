import React, { useState, useEffect, FormEvent, useMemo, useRef } from 'react';
import { Button, Dropdown, MultiSelect } from '@agro1desenvolvimento/react-components';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { find } from 'lodash';
import { hideLoader, showLoader } from '../../components/Loader/loaderSlice';
import {
  CustomersService,
  CropsService,
  HarvestsService,
  IndexSummaries,
  NotificationsService,
} from '../../services';
import { Crop } from '../../@types/crop';
import { Harvest } from '../../@types/harvest';
import { Customer } from '../../@types/customer';

const CsvPage = () => {
  const dispatch = useDispatch();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [crops, setCrops] = useState<Crop[]>([]);
  const [seasons, setSeasons] = useState<Harvest[]>([]);
  const [customerIds, setCustomerIds] = useState<string[]>([]);
  const [cropId, setCropId] = useState<string>();
  const [seasonId, setSeasonId] = useState<string>();
  const [formValid, setFormValid] = useState(false);
  const linkEl = useRef<HTMLAnchorElement>(null);

  const loadData = async () => {
    try {
      dispatch(showLoader());
      const [newCustomers, newCrops, newSeasons] = await Promise.all([
        CustomersService.find(),
        CropsService.find(),
        HarvestsService.find(),
      ]);
      setCustomers(newCustomers);
      setCrops(newCrops);
      setSeasons(newSeasons);
    } catch (exception) {
      console.error(exception);
      NotificationsService.error({ message: 'Falha ao carregar dados.' });
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useMemo(() => {
    setFormValid(!!cropId && !!seasonId && !!customerIds);
  }, [cropId, seasonId, customerIds]);

  const generateCsv = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      dispatch(showLoader());

      const response = await IndexSummaries.generateCsv({
        seasonUuid: seasonId,
        cropUuid: cropId,
        customerIds,
      });
      if (!linkEl.current) return;

      linkEl.current.href = URL.createObjectURL(response);
      linkEl.current.download = `${[
        'index-summaries',
        find(seasons, { uuid: seasonId })?.description,
        find(crops, { uuid: cropId })?.description,
      ].join('-')}.csv`;
      linkEl.current.click();
    } catch (error: any) {
      let message = 'Falha ao gerar CSV';
      if (error?.response?.status === 400 && error.response.data) {
        message = await error.response.data.text();
      }
      NotificationsService.error({ message });
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <div className="index-summaries-csv p-m-5">
      <div className="page-title">
        <h1 className="title">CSV de Resumo de Índices</h1>
      </div>

      <form onSubmit={generateCsv}>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col">
            <label aria-labelledby="seasonId" htmlFor="seasonId">Safra</label>
            <Dropdown
              autoFocus
              value={seasonId}
              name="seasonId"
              id="seasonId"
              options={seasons}
              optionLabel="description"
              optionValue="uuid"
              onChange={(event) => setSeasonId(event.value)}
              required
              className={classNames({ 'p-invalid': !seasonId })}
              placeholder="Selecione a Safra"
            />
          </div>

          <div className="p-field p-col">
            <label aria-labelledby="cropId" htmlFor="cropId">Atividade</label>
            <Dropdown
              value={cropId}
              name="cropId"
              id="cropId"
              options={crops}
              optionLabel="description"
              optionValue="uuid"
              onChange={(event) => setCropId(event.value)}
              required
              className={classNames({ 'p-invalid': !cropId })}
              placeholder="Selecione a Atividade"
            />
          </div>

          <div className="p-field p-col">
            <label aria-labelledby="customerIds" htmlFor="customerIds">Cliente</label>
            <MultiSelect
              value={customerIds}
              name="customerIds"
              id="customerIds"
              options={customers}
              optionLabel="name"
              optionValue="id"
              filter
              onChange={(event) => setCustomerIds(event.value)}
              className={classNames({ 'p-invalid': !customerIds })}
              placeholder="Selecione um Cliente"
            />
          </div>
        </div>

        <div className="p-d-flex p-flex-row-reverse">
          <Button
            type="submit"
            label="Gerar CSV"
            className="p-button-success"
            icon="pi pi-cog"
            disabled={!formValid}
          />
        </div>
      </form>

      <a className="p-d-none" ref={linkEl} href="#<DYNAMIC>">(Hidden) Only to download CSV</a>
    </div>
  );
};

export default CsvPage;
