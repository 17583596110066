import React from 'react';

const Checkbox: React.FC<BaseCheckboxProps> = ({ label, value, onChange, disabled = false }) => (

  <label className="checkbox">
    <input
      type="checkbox"
      onChange={onChange}
      checked={value}
      disabled={disabled}
    />
    {label}
  </label>
);

export default Checkbox;

export type BaseCheckboxProps = {
  label: string;
  value: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}
