import React, { useState } from 'react';
import { HashRouter } from 'react-router-dom';
import { FORMATTED_ROUTES } from '../AgroanalizaRouters';
import { RoutesContext, useIsCustomer, useVisibility } from '../../hooks';
import NavbarHeader from './NavbarHeader';
import NavbarSide from './NavbarSide';
import { AgroanalizaRouters } from '..';
import ClientNavbarSide from './ClientNavbarSide';

const Navbar: React.FC = () => {
  const [navbarVisible, toggleNavbarVisible] = useVisibility(true);
  const isCustomer = useIsCustomer();
  const [fisrtBoard, setFirstBoard] = useState('');

  const setFirstBoardHandler = (boardId: string) => setFirstBoard(boardId);

  return (
    <div className="agroanalisa-navbar">
      <HashRouter>
        <RoutesContext.Provider value={FORMATTED_ROUTES}>
          <NavbarHeader onClickBars={toggleNavbarVisible} isOpen={navbarVisible} />
          <div className="pages">
            {
              isCustomer ? (
                <ClientNavbarSide
                  visible={navbarVisible}
                  setFirstBoardHandler={setFirstBoardHandler}
                />
              ) : <NavbarSide visible={navbarVisible} />
            }
            <div>
              <AgroanalizaRouters fisrtBoard={fisrtBoard} />
            </div>
          </div>
        </RoutesContext.Provider>
      </HashRouter>
    </div>
  );
};

export default Navbar;
