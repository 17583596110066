import React from 'react';
import Modal from '../Modal';

const CreateEditModal: React.FC<CreateEditModaProps> = ({
  children,
  title,
  onClose,
  onClosed,
  visible,
}) => (
  <Modal.Root
    visible={visible}
    persistent={false}
    onClose={onClose}
    onClosed={onClosed}
  >
    <div className="create-edit-modal">
      <p className="subtitle">{title}</p>
      {children}
    </div>
  </Modal.Root>
);
export default CreateEditModal;

export type CreateEditModaProps = {
  title: string;
  onClose?: () => void;
  onClosed?: () => void;
  visible: boolean;
}
