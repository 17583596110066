import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { useIsActive, useModalClasses } from './hooks';
import RootModal from './RootModal';
import { MODAL_ANIMATION_DURATION } from '../../utils';

// TODO: Mover conteúdo da página gradativamente ao abrir/fechar a modal
const Modal: ModalComponent = ({
  children,
  visible,
  onClosed = () => [],
  contentAnimation = 'fade-center',
  className = '',
}) => {
  const { isActive, onEnter, onExited } = useIsActive(visible, onClosed);
  const modalClasses = useModalClasses(
    visible || isActive,
    className,
    'modal',
    'is-inline-block',
    contentAnimation,
  );

  return (
    <div className={modalClasses}>
      <CSSTransition
        classNames={contentAnimation}
        timeout={MODAL_ANIMATION_DURATION}
        in={visible}
        onEnter={onEnter}
        onExited={onExited}
      >
        <div className="shadow">
          {children}
        </div>
      </CSSTransition>
    </div>
  );
};

Modal.Root = RootModal;

export default Modal;

export type BaseModalProps = {
  visible: boolean,
  contentAnimation?: ContentAnimation,
  persistent?: boolean,
  onClose?: () => void,
  onClosed?: () => void,
  className?: string,
};

type ModalComponent = React.FC<
  Omit<BaseModalProps, 'onClose' | 'persistent'>
> & {
  Root: React.FC<BaseModalProps>
}

type ContentAnimation = 'fade-right' | 'fade-left' | 'fade-center'
