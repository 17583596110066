import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { loginService } from '@agro1desenvolvimento/apis-js-package';
import NotFound from '../../views/NotFound';
import { FormattedRoute, Route as RouterType } from '../../@types';
import CropsPage from '../../views/Crops';
import HarvestsPage from '../../views/Harvests';
import RegionsPage from '../../views/Regions';
import IndexPage from '../../views/Indexes';
import CustomersPage from '../../views/Customers';
import ForbiddenPage from '../../views/Forbidden';
import IndexSummaryPage from '../../views/IndexSummaries';
import DashboardsPage from '../../views/Dashboards';
import ClientDashboardsPage from '../../views/ClientDashboards';
import { useIsCustomer } from '../../hooks';

export const ROUTES: RouterType[] = [
  {
    component: CropsPage,
    label: 'Atividades',
    roleAction: 'agroanalisa.crops',
    link: [
      '/atividades',
      '/atividades/:action(novo)',
      '/atividades/:action(editar)/:id',
    ],
    rootLink: '/atividades',
  },
  {
    component: HarvestsPage,
    label: 'Safras',
    roleAction: 'agroanalisa.harvests',
    link: [
      '/safras',
      '/safras/:action(novo)',
      '/safras/:action(editar)/:id',
    ],
    rootLink: '/safras',
  },
  {
    component: RegionsPage,
    label: 'Regiões',
    roleAction: 'agroanalisa.regions',
    link: [
      '/regioes',
      '/regioes/:action(novo)',
      '/regioes/:action(editar)/:id',
    ],
    rootLink: '/regioes',
  },
  {
    component: IndexPage,
    label: 'Índices',
    roleAction: 'agroanalisa.indexes',
    link: [
      '/indices',
      '/indices/:action(novo)',
      '/indices/:action(editar)/:id',
    ],
    rootLink: '/indices',
  },
  {
    component: IndexSummaryPage,
    label: 'Resumo de Índices',
    roleAction: 'agroanalisa.index_summaries',
    link: [
      '/resumo_indices/csv',
    ],
    rootLink: '/resumo_indices/csv',
  },
  {
    component: CustomersPage,
    label: 'Clientes',
    roleAction: 'agroanalisa.customers',
    link: [
      '/clientes',
      '/clientes/:action(novo)',
      '/clientes/:action(editar)/:id',
      '/clientes/:customerId/:subRoute(propriedades)',
      '/clientes/:customerId/:subRoute(propriedades)/:action(nova)',
      '/clientes/:customerId/:subRoute(propriedades)/:action(editar)/:id',
      '/clientes/:customerId/:subRoute(contratos)',
      '/clientes/:customerId/:subRoute(contratos)/:action(novo)',
      '/clientes/:customerId/:subRoute(contratos)/:action(editar)/:id',
    ],
    rootLink: '/clientes',
  },
  {
    component: DashboardsPage,
    label: 'Dashboards',
    roleAction: 'agroanalisa.dashboards',
    link: [
      '/dashboards',
      '/dashboards/:action(novo)',
      '/dashboards/:action(editar)/:id',
      '/dashboards/:subRoute(board)/:id',
      '/dashboards/:subRoute(board)/:id/:action(nova)',
      '/dashboards/:subRoute(board)/:id/:action(editar)/:idBoard',
    ],
    rootLink: '/dashboards',
  },
  {
    component: ClientDashboardsPage,
    label: 'Dashboards',
    roleAction: 'agroanalisa.customer_panel',
    link: [
      '/board/:id',
    ],
    rootLink: '/board',
  },
];

export const FORMATTED_ROUTES: FormattedRoute[] = ROUTES.map((route) => ({
  rootLink: route.link as string,
  ...route,
})).sort((routeA, routeB) => routeA.label.localeCompare(routeB.label));

export const isRouteForbidden = (roleAction: string) => !loginService
  .validateAccess(roleAction);

const AgroanalizaRouters: React.FC<RouteParams> = ({ fisrtBoard }) => {
  const history = useHistory();
  const isCustomer = useIsCustomer();

  useEffect(() => {
    if (!isCustomer) return;
    if (fisrtBoard && history.location.pathname === '/') {
      history.push(`/board/${fisrtBoard}`);
    }
  }, [isCustomer, fisrtBoard, history]);

  return (
    <Switch>
      {FORMATTED_ROUTES.map(({ link, component, rootLink }) => (
        <Route exact key={rootLink} path={link} component={component} />
      ))}
      <Route exact path="/" />
      <Route exact path="/403" component={ForbiddenPage} />
      <Route component={NotFound} />
    </Switch>
  );
};
type RouteParams = {
  fisrtBoard?: string,
};

export default AgroanalizaRouters;
