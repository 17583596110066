import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../components/Loader/loaderSlice';
import { IndexesService, NotificationsService } from '../../services';
import { Checkbox, FloatingLabelInput, FormulaInfo } from '../../components';
import { MODAL_ANIMATION_DURATION } from '../../utils';

const CreateEditPage: React.FC<PropsTypes> = ({ id, onClose, onSave }) => {
  const dispatch = useDispatch();
  const [description, setDescription] = useState('');
  const [level, setLevel] = useState('');
  const [formula, setFormula] = useState('');
  const [totalizer, setTotalizer] = useState(false);

  const isValid = useMemo(() => {
    let valid = description && level;

    if (totalizer) {
      valid = valid && formula;
    }

    return valid;
  }, [description, level, totalizer, formula]);

  const loadData = async () => {
    if (!id) {
      cleanFields();
      return;
    }

    dispatch(showLoader());
    try {
      const index = await IndexesService.findOneById(id);
      setDescription(index.description);
      setLevel(index.level);
      setTotalizer(index.totalizer);
      setFormula(index.formula);
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao carregar Índice para Edição' });
      onClose();
    } finally {
      dispatch(hideLoader());
    }
  };

  const onClickSave = async () => {
    const indexData = { description, level, totalizer, formula };

    try {
      if (!id) {
        await IndexesService.create(indexData);
      } else {
        await IndexesService.update(id, indexData);
      }
      onSave();
      onClickCancel();
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao Salvar Índice' });
    }
  };

  const cleanFields = () => {
    setTimeout(() => {
      setDescription('');
      setTotalizer(false);
      setLevel('');
    }, MODAL_ANIMATION_DURATION);
  };

  const onClickCancel = () => {
    onClose();
    cleanFields();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { loadData(); }, [id]);

  return (
    <div className="create-edit-page">
      <FloatingLabelInput
        data-testid="descricao"
        label="Descrição"
        onChange={(e) => setDescription(e.currentTarget.value)}
        value={description}
      />
      <div className="field is-grouped columns">
        <div className="control is-four-fifths column">
          <FloatingLabelInput
            data-testId="nivel"
            label="Nível"
            onChange={(e) => setLevel(e.currentTarget.value)}
            value={level}
          />
        </div>
        <div className="control column">
          <Checkbox
            label="Totalizador"
            onChange={() => setTotalizer(!totalizer)}
            value={totalizer}
          />
        </div>
      </div>
      <FloatingLabelInput
        label="Fórmula"
        onChange={(e) => setFormula(e.currentTarget.value)}
        value={formula}
        disabled={!totalizer}
        title={totalizer ? 'Fórmula' : 'Somente totalizadores precisam de fórmula'}
      />
      <FormulaInfo />
      <div className="field is-grouped is-grouped-right">
        <p className="control">
          <button className="button is-light" onClick={onClickCancel}>Cancelar</button>
        </p>
        <p className="control">
          <button
            className="button is-link"
            disabled={!isValid}
            onClick={onClickSave}
          >
            Salvar
          </button>
        </p>
      </div>
    </div>
  );
};

export default CreateEditPage;

type PropsTypes = {
  id?: string;
  onClose: () => void;
  onSave: () => void;
}
