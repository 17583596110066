import { Button, Dialog, InputText } from '@agro1desenvolvimento/react-components';
import React, { useEffect, useState, useCallback } from 'react';
import { Board } from '../../@types/board';
import { CrudCreateUpdate } from '../../utils';
import { BoardsService } from '../../services';

const Footer: React.FC<
  { onCancel: () => void, onConfirm: () => void, valid: boolean, loading: boolean }
> = ({ onCancel, onConfirm, valid, loading }) => (
  <>
    <Button
      label="Cancelar"
      icon="pi pi-times"
      onClick={onCancel}
      className="p-button-text"
      disabled={loading}
    />
    <Button
      label="Salvar"
      disabled={!valid}
      icon="pi pi-check"
      onClick={onConfirm}
      loading={loading}
    />
  </>
);

const DialogSaveChart: React.FC<PropTypes> = ({
  id,
  idBoard,
  onHide,
  visible,
  item,
  afterSave,
}) => {
  const [dashboardTitle, setDashboardTitle] = useState('');
  const [loading, setLoading] = useState(false);

  const save = useCallback(async () => {
    try {
      // eslint-disable-next-line no-param-reassign
      item.titulo = dashboardTitle;
      if (idBoard) {
        await BoardsService.update(id, idBoard, item);
      } else {
        await BoardsService.create(item, id);
      }

      afterSave();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, dashboardTitle]);

  useEffect(() => {
    setDashboardTitle(item.titulo);
  }, [item.titulo]);

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header="Salvar gráfico"
      footer={(
        <Footer
          onCancel={onHide}
          onConfirm={save}
          valid={!!dashboardTitle}
          loading={loading}
        />
      )}
    >
      <InputText
        value={dashboardTitle}
        onChange={({ currentTarget: { value } }) => setDashboardTitle(value)}
      />
    </Dialog>
  );
};

interface PropTypes {
  id: string,
  idBoard: string,
  visible: boolean,
  onHide: () => void,
  item: CrudCreateUpdate<Board>,
  afterSave: () => void
}

export default DialogSaveChart;
