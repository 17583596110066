import { keysToSnakeCase } from '@agro1desenvolvimento/utils-js';
import { APIAgroanalisaService } from '.';
import { Farm } from '../@types/farm';

class Farms {
  private getEndpoint = (customerId: string) => `v1/customers/${customerId}/farms`;

  async findOneById({ customerId, farmId }: QueryIdsType) {
    const { data } = await APIAgroanalisaService.get<Farm>(
      `${this.getEndpoint(customerId)}/${farmId}`,
    );
    return data;
  }

  async find(customerId: string, q = '') {
    const { data } = await APIAgroanalisaService.get<Farm[]>(
      this.getEndpoint(customerId), { params: { q } },
    );
    return data;
  }

  async create(customerId: string, farmData: FarmData) {
    const farm = keysToSnakeCase(farmData);

    const { data } = await APIAgroanalisaService.post<Farm>(
      this.getEndpoint(customerId), { farm },
    );
    return data;
  }

  async update({ customerId, farmId }: QueryIdsType, farmData: FarmData) {
    const farm = keysToSnakeCase(farmData);

    const { data } = await APIAgroanalisaService.patch<Farm>(
      `${this.getEndpoint(customerId)}/${farmId}`,
      { farm },
    );
    return data;
  }

  remove({ customerId, farmId }: QueryIdsType) {
    return APIAgroanalisaService.delete(`${this.getEndpoint(customerId)}/${farmId}`);
  }
}

type QueryIdsType = Record<'customerId' | 'farmId', string>

export interface FarmData {
  name: string;
  area: number;
  cityUuid?: string;
  regionUuid?: string;
}

export default new Farms();
