import { APIAgroanalisaService } from '.';
import { Region } from '../@types/region.d';

class Regions {
  private baseUrl = 'v1/regions';

  async findOneById(id: string) {
    const { data } = await APIAgroanalisaService.get<Region>(`${this.baseUrl}/${id}`);
    return data;
  }

  async find(q?: string) {
    const { data } = await APIAgroanalisaService.get<Region[]>(this.baseUrl, { params: { q } });
    return data;
  }

  async create(region: RegionDataInsert) {
    const { data } = await APIAgroanalisaService.post<Region>(this.baseUrl, { region });
    return data;
  }

  async update(id: string, region: RegionDataInsert) {
    const { data } = await APIAgroanalisaService.patch<Region>(`${this.baseUrl}/${id}`,
      { region });
    return data;
  }

  remove(id: string) {
    return APIAgroanalisaService.delete(`${this.baseUrl}/${id}`);
  }
}

interface RegionData {
  id: string;
  description: string;
  uf: string;
  uuid: string;
}

type RegionDataInsert = Omit<RegionData, 'id' | 'uuid'>

export default new Regions();
