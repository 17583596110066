import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../../components/Loader/loaderSlice';
import { BoardFilters } from '../../../components';
import { DashboardsRouterType } from '..';
import Header from '../../../components/Dashboards/Header';
import { BoardsService, DashboardsService } from '../../../services';
import { Board } from '../../../@types/board';
import Boards from '../../../components/Dashboards/Board';
import BoardItem from '../../../components/Dashboards/BoardItem';
import ChartRenderer from '../../../components/Dashboards/ChartRenderer';
import { Dashboard } from '../../../@types/dashboard';

const defaultLayout = (item: Board) => ({
  x: item.layout.x || 0,
  y: item.layout.y || 0,
  w: item.layout.w || 4,
  h: item.layout.h || 8,
  minW: 4,
  minH: 8,
});

const Empty = () => (
  <div>
    <h2 className="p-text-center">Nenhum gráfico cadastrado.</h2>
  </div>
);

const BoardPage: React.FC<DashboardsRouterType> = ({ match, location, history }) => {
  const dispatch = useDispatch();
  const [dashboard, setDashboard] = useState<Dashboard>();
  const [board, setBoard] = useState<Board[]>([]);
  const { id } = match.params;

  useEffect(() => {
    const fetchDashboards = async () => {
      try {
        if (!id) return;
        setDashboard((await DashboardsService.findOneById(id)));
      } catch (error) {
        console.error(error);
      }
    };
    fetchDashboards();
  }, [id]);

  const loadData = useCallback(async () => {
    dispatch(showLoader());
    try {
      setBoard((await BoardsService.find(id) ?? []));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoader());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="dashboard">
      <div
        className="dashboard-title-content p-d-grid p-jc-between p-ai-center p-ml-2 p-mr-2 p-card"
      >
        <span className="dashboard-title">{dashboard ? dashboard.description : ''}</span>
        <hr />
        <BoardFilters />
      </div>
      <Header history={history} location={location} match={match} />
      {board && board.length && id ? (
        <Boards dashboardItems={board} id={id}>
          {board.map((item) => (
            <div key={item.id} data-grid={defaultLayout(item)}>
              <BoardItem
                id={id}
                title={item.titulo}
                itemId={item.id}
                onDeleted={() => loadData()}
              >
                <ChartRenderer
                  vizState={item.payload}
                  extraVizState={item.extraPayload}
                />
              </BoardItem>
            </div>
          ))}
        </Boards>
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default BoardPage;
