import { keysToSnakeCase } from '@agro1desenvolvimento/utils-js';
import { APIAgroanalisaService } from '.';
import { Contract } from '../@types/contract';

class Contracts {
  private getEndpoint = (customerId: string) => `v1/customers/${customerId}/contracts`;

  async findOneById({ customerId, contractId }: QueryIdsType) {
    const { data } = await APIAgroanalisaService.get<Contract>(
      `${this.getEndpoint(customerId)}/${contractId}`,
    );
    return data;
  }

  async find(customerId: string, q = '') {
    const { data } = await APIAgroanalisaService.get<Contract[]>(
      this.getEndpoint(customerId), { params: { q } },
    );
    return data;
  }

  async create(customerId: string, contractData: ContractData) {
    const contract = keysToSnakeCase(contractData);

    const { data } = await APIAgroanalisaService.post<Contract>(
      this.getEndpoint(customerId), { contract },
    );
    return data;
  }

  async update({ customerId, contractId }: QueryIdsType, contractData: ContractData) {
    const contract = keysToSnakeCase(contractData);

    const { data } = await APIAgroanalisaService.patch<Contract>(
      `${this.getEndpoint(customerId)}/${contractId}`,
      { contract },
    );
    return data;
  }

  remove({ customerId, contractId }: QueryIdsType) {
    return APIAgroanalisaService.delete(`${this.getEndpoint(customerId)}/${contractId}`);
  }
}

type QueryIdsType = Record<'customerId' | 'contractId', string>

export interface ContractData {
  area: number;
  active: boolean;
  startDate: Date;
  endDate: Date;
}

export default new Contracts();
