import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../components/Loader/loaderSlice';
import { RegionsService, NotificationsService } from '../../services';
import { FloatingLabelInput, SelectDropdown } from '../../components';
import { MODAL_ANIMATION_DURATION } from '../../utils';

const CreateEditPage: React.FC<PropsTypes> = ({ id, onClose, onSave }) => {
  const dispatch = useDispatch();
  const [description, setDescription] = useState('');
  const [uf, setUf] = useState('');
  const saveDisabled = useMemo(() => !description || !uf, [description, uf]);

  const loadData = async () => {
    if (!id) {
      cleanFields();
      return;
    }

    dispatch(showLoader());
    try {
      const region = await RegionsService.findOneById(id);
      setDescription(region.description);
      setUf(region.uf);
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao carregar Região para Edição' });
      onClose();
    } finally {
      dispatch(hideLoader());
    }
  };

  const onClickSave = async () => {
    const regionData = { description, uf };

    try {
      if (!id) {
        await RegionsService.create(regionData);
      } else {
        await RegionsService.update(id, regionData);
      }
      onSave();
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao Salvar Região' });
    } finally {
      onClickCancel();
    }
  };

  const cleanFields = () => {
    setTimeout(() => {
      setDescription('');
      setUf('');
    }, MODAL_ANIMATION_DURATION);
  };

  const onClickCancel = () => {
    onClose();
    cleanFields();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { loadData(); }, [id]);

  return (
    <div className="create-edit-page">
      <div className="field is-grouped columns">
        <div className="control is-four-fifths column">
          <FloatingLabelInput
            label="Descrição"
            onChange={(e) => setDescription(e.currentTarget.value)}
            value={description}
          />
        </div>
        <div className="control column">
          <SelectDropdown
            label="UF"
            onChange={(e) => setUf(e.currentTarget.value)}
            value={uf}
            required
          >
            {UFSignals.map((UFSignal) => (
              <option key={UFSignal} value={UFSignal}>{UFSignal}</option>
            ))}
          </SelectDropdown>
        </div>
      </div>
      <div className="field is-grouped is-grouped-right">
        <p className="control">
          <button className="button is-light" onClick={onClickCancel}>Cancelar</button>
        </p>
        <p className="control">
          <button
            className="button is-link"
            disabled={saveDisabled}
            onClick={onClickSave}
          >
            Salvar
          </button>
        </p>
      </div>
    </div>
  );
};

export default CreateEditPage;

type PropsTypes = {
  id?: string;
  onClose: () => void;
  onSave: () => void;
}

const UFSignals = [
  'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'ES',
  'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB',
  'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO',
  'RR', 'SC', 'SP', 'SE', 'TO', 'DF',
];
