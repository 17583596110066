import React from 'react';
import ReactDOM from 'react-dom';
import './styles/styles.scss';
import './config/fontawesome';
import { Provider } from 'react-redux';
import App from './App';
import store from './app/store';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.compat.css';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
