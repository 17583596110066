import { APIAgroanalisaService } from '.';
import { Dashboard } from '../@types/dashboard';

class Dashboards {
  private baseUrl = 'v1/dashboards';

  async findOneById(id: string) {
    const { data } = await APIAgroanalisaService.get<Dashboard>(`${this.baseUrl}/${id}`);
    return data;
  }

  async find(q?: string) {
    const { data } = await APIAgroanalisaService.get<Dashboard[]>(this.baseUrl, { params: { q } });
    return data;
  }

  async create(dashboard: DashboardDataInsert) {
    const { data } = await APIAgroanalisaService.post<Dashboard>(this.baseUrl, { dashboard });
    return data;
  }

  async update(id: string, dashboard: DashboardDataInsert) {
    const { data } = await APIAgroanalisaService.patch<Dashboard>(`${this.baseUrl}/${id}`,
      { dashboard });
    return data;
  }

  remove(id: string) {
    return APIAgroanalisaService.delete(`${this.baseUrl}/${id}`);
  }
}

export interface DashboardData {
  id: string;
  description: string;
  uuid: string;
}

type DashboardDataInsert = Omit<DashboardData, 'id' | 'uuid'>

export default new Dashboards();
