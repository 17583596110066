import React from 'react';

const NotificationButton: React.FC<NotificationButtonProps> = ({
  text,
  onClickYes,
  onClickNo,
}) => (
  <div>
    <p>{text}</p>
    <div className="field is-grouped is-grouped-right">
      <p className="control">
        <button className="button is-light" onClick={onClickNo}>Não</button>
      </p>
      <p className="control">
        <button
          className="button is-danger"
          onClick={onClickYes}
        >
          Sim
        </button>
      </p>
    </div>
  </div>
);

export default NotificationButton;

export type NotificationButtonProps = {
  text: string | React.ReactNode;
  onClickYes: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClickNo?: () => void;
}
