import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import BoardPage from './Board';
import CreateEditDashboardPage from './Board/CreateEditBoardPage';
import DashboardsPage from './DashboardsPage';

export default (props: DashboardsRouterType) => {
  const { action, subRoute } = props.match.params;
  let component;

  if (subRoute === 'board' && (action === 'nova' || action === 'editar')) {
    component = <CreateEditDashboardPage />;
  } else if (subRoute === 'board') {
    component = <BoardPage {...props} />;
  } else {
    component = <DashboardsPage {...props} />;
  }

  return component;
};

export type DashboardsRouterType = RouteComponentProps<RouteParams>;

type RouteParams = {
  action?: 'novo' | 'nova' | 'editar',
  id?: string,
  subRoute?: 'board',
  idBoard: string,
};
