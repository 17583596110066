import { combineReducers } from '@reduxjs/toolkit';

import boardFiltersReducer from '../components/BoardFilters/boardFiltersSlice';
import loaderReducer from '../components/Loader/loaderSlice';

const rootReducer = combineReducers({ boardFiltersReducer, loaderReducer });

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
