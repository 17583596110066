import React, { useMemo } from 'react';
import RGL from 'react-grid-layout';
import { useIsMobile } from '@agro1desenvolvimento/react-hooks';
import 'react-grid-layout/css/styles.css';
import { pick } from 'lodash';
import ReactResizeDetector from 'react-resize-detector';
import { Board as DashboardType, DashboardLayout } from '../../@types/board';
import { BoardsService, NotificationsService } from '../../services';
import { useIsCustomer } from '../../hooks';

const DASHBOARD_LAYOUT_KEY: (keyof DashboardLayout)[] = ['x', 'y', 'w', 'h'];

const isSameDashboardLayout = (
  layoutA: DashboardLayout,
  layoutB: DashboardLayout,
) => (
  DASHBOARD_LAYOUT_KEY
    .every((key) => layoutA[key] === layoutB[key])
);

const Dashboard: React.FC<
  { dashboardItems: DashboardType[], id: string }
> = ({ children, dashboardItems, id }) => {
  const isMobile = useIsMobile();
  const dashboardCols = useMemo(() => (isMobile ? 6 : 12), [isMobile]);
  const isCustomer = useIsCustomer();
  const isResizableAndDraggable = !isCustomer && !isMobile;

  const onLayoutChange = (newLayout: RGL.Layout[]) => {
    if (isMobile) return;
    newLayout.forEach(async (layout) => {
      const item = dashboardItems.find((dashboard) => dashboard.id === layout.i);

      if (!item || isSameDashboardLayout(item.layout, layout)) return;

      item.layout = pick(layout, DASHBOARD_LAYOUT_KEY);

      try {
        await BoardsService.update(id, layout.i, item);
      } catch (error) {
        NotificationsService.error({ message: 'Falha ao reposicionar Dashboard' });
        console.error(error);
      }
    });
  };

  return (
    <ReactResizeDetector refreshMode="debounce" handleWidth>{({ width }) => (
      <RGL
        cols={dashboardCols}
        rowHeight={50}
        onLayoutChange={onLayoutChange}
        isResizable={isResizableAndDraggable}
        isDraggable={isResizableAndDraggable}
        width={width || 0}
        verticalCompact
      >
        {children}
      </RGL>
    )}
    </ReactResizeDetector>
  );
};

export default Dashboard;
