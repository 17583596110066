import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import Dropdown from '../Dropdown';

const DropdownButton: React.FC <{ onClick: () => void }> = ({ onClick }) => (
  <button
    className="button borderless backgroundless"
    aria-haspopup="true"
    aria-controls="dropdown-menu"
    onClick={(event) => {
      event.stopPropagation();
      onClick();
    }}
  >
    <span className="icon">
      <FontAwesomeIcon icon="ellipsis-v" className="fa-lg" />
      <i className="fas fa-angle-down" aria-hidden="true" />
    </span>
  </button>
);

const TableDropdownButton: React.FC<TableDropdownButtonProps> = ({
  isActive,
  onClickOver,
  onClickDropdownButton,
  redirectOnClickEdit,
  onClickDelete,
  children,
}) => (
  <Dropdown
    isActive={isActive}
    openingAlignment="right"
    onClickOver={onClickOver}
    button={(
      <DropdownButton onClick={onClickDropdownButton} />
    )}
  >
    <div className="table-dropdown-button">
      <Link className="edit-button" to={redirectOnClickEdit}>
        <span className="icon">
          <FontAwesomeIcon icon="edit" className="fa" />
        </span>
        Editar
      </Link>
      {onClickDelete && (
        <button className="delete-button" onClick={onClickDelete}>
          <span className="icon">
            <FontAwesomeIcon icon="trash-alt" className="fa" />
          </span>
          Excluir
        </button>
      )}
      {children}
    </div>
  </Dropdown>
);

export default TableDropdownButton;

export type TableDropdownButtonProps = {
  isActive: boolean;
  onClickOver?: () => void;
  onClickDropdownButton: () => void;
  redirectOnClickEdit: string;
  onClickDelete?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};
