import { APIAgroanalisaService } from '.';
import { Board, BoardType } from '../@types/board';
import { CrudCreateUpdate } from '../utils';

class Boards {
  private baseUrl = 'v1/dashboards';

  async findOneById(id: string, idBoard: string) {
    const { data } = await APIAgroanalisaService.get<BoardType>(
      `${this.baseUrl}/${id}/boards/${idBoard}`,
    );
    if (data) return { ...data.data, id: data.id };
    return '';
  }

  async find(q?: string) {
    const { data } = await APIAgroanalisaService.get<BoardType[]>(`${this.baseUrl}/${q}/boards`);
    if (data) return data.map((element: BoardType) => ({ ...element.data, id: element.id }));
    return [];
  }

  async create(board: BoardDataInsert, id: string) {
    const { data } = await APIAgroanalisaService.post<CrudCreateUpdate<Board>>(
      `${this.baseUrl}/${id}/boards`,
      { data: board },
    );
    return data;
  }

  async update(id: string, idBoard: string, board: BoardDataInsert) {
    const { data } = await APIAgroanalisaService.patch<CrudCreateUpdate<Board>>(
      `${this.baseUrl}/${id}/boards/${idBoard}`,
      { data: board },
    );
    return data;
  }

  remove(id: string, idBoard: string) {
    return APIAgroanalisaService.delete(`${this.baseUrl}/${id}/boards/${idBoard}`);
  }
}

type BoardDataInsert = Omit<Board, 'id' | 'uuid'>
export default new Boards();
