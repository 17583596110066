import { APIAgroanalisaService } from '.';
import { Harvest } from '../@types/harvest.d';

class Harvests {
  private baseUrl = 'v1/harvests';

  async findOneById(id: string) {
    const { data } = await APIAgroanalisaService.get<Harvest>(`${this.baseUrl}/${id}`);
    return data;
  }

  async find(q?: string) {
    const { data } = await APIAgroanalisaService.get<Harvest[]>(this.baseUrl, { params: { q } });
    return data;
  }

  async create(harvest: HarvestDataInsert) {
    const { data } = await APIAgroanalisaService.post<Harvest>(this.baseUrl, { harvest });
    return data;
  }

  async update(id: string, harvest: HarvestDataInsert) {
    const { data } = await APIAgroanalisaService.patch<Harvest>(`${this.baseUrl}/${id}`,
      { harvest });
    return data;
  }

  remove(id: string) {
    return APIAgroanalisaService.delete(`${this.baseUrl}/${id}`);
  }
}

export interface HarvestData {
  id: string;
  description: string;
  current: boolean;
  uuid: string;
}

type HarvestDataInsert = Omit<HarvestData, 'id' | 'uuid'>

export default new Harvests();
