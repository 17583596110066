import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { useIsActive, useModalClasses } from './hooks';
import { BaseModalProps } from '.';
import { MODAL_ANIMATION_DURATION } from '../../utils';

const RootModal: React.FC<BaseModalProps> = ({
  children,
  visible,
  onClose = () => [],
  onClosed = () => [],
  contentAnimation = 'fade-center',
  persistent,
  className = '',
}) => {
  const [rootElement] = useState<HTMLElement>(() => document.getElementById('root') as HTMLElement);
  const { isActive, onEnter, onExited } = useIsActive(visible, onClosed);
  const modalClasses = useModalClasses(visible || isActive, className, 'modal', contentAnimation);

  return ReactDOM.createPortal((
    <div className={modalClasses}>
      <CSSTransition
        classNames="background"
        timeout={MODAL_ANIMATION_DURATION}
        in={visible}
        onEnter={onEnter}
        onExited={onExited}
      >
        <div
          role="button"
          tabIndex={0}
          className="modal-background"
          onClick={persistent ? undefined : onClose}
        />
      </CSSTransition>
      <CSSTransition
        classNames={contentAnimation}
        timeout={MODAL_ANIMATION_DURATION}
        in={visible}
      >
        <div className="shadow">
          {children}
        </div>

      </CSSTransition>
    </div>
  ), rootElement);
};

export default RootModal;
