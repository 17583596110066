import { APIAgroanalisaService } from '.';
import { Crop } from '../@types/crop';

class Crops {
  private baseUrl = 'v1/crops';

  async findOneById(id: string) {
    const { data } = await APIAgroanalisaService.get<Crop>(`${this.baseUrl}/${id}`);
    return data;
  }

  async find(q?: string) {
    const { data } = await APIAgroanalisaService.get<Crop[]>(this.baseUrl, { params: { q } });
    return data;
  }

  async create(crop: CropDataInsert) {
    const { data } = await APIAgroanalisaService.post<Crop>(this.baseUrl, { crop });
    return data;
  }

  async update(id: string, crop: CropDataInsert) {
    const { data } = await APIAgroanalisaService.patch<Crop>(`${this.baseUrl}/${id}`,
      { crop });
    return data;
  }

  remove(id: string) {
    return APIAgroanalisaService.delete(`${this.baseUrl}/${id}`);
  }
}

export interface CropData {
  id: string;
  description: string;
  uuid: string;
}

type CropDataInsert = Omit<CropData, 'id' | 'uuid'>

export default new Crops();
