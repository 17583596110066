export const LIST_LABEL_FONT_SIZE = 10;

export const log10 = (number: number) => Math.log(number) / Math.log(10);

export const log10Precise = (number: number) => Math.round((100 * log10(number))) / 100;

export const shouldDisplayLabel = (barObject: any, value: any) => {
  const labelSize = LIST_LABEL_FONT_SIZE * (Math.floor(log10(value)));

  if (!Number.isFinite(labelSize)) return false;
  if (barObject.height < labelSize) return false;
  if (barObject.width < labelSize) return false;

  return true;
};
