import classNames from 'classnames';
import React, { useMemo, useState } from 'react';

const FloatingLabelInput: React.FC<BaseInputProps> = ({
  label,
  onChange,
  value,
  testId,
  disabled,
  title,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const FloatLabelInputClass = useMemo(
    () => classNames(
      { 'is-active': isFocused || value },
      'label',
    ),
    [isFocused, value],
  );

  const setFocused = () => setIsFocused(true);
  const setBlurred = () => setIsFocused(false);

  return (
    <div className="field has-floating-label">
      <label className={FloatLabelInputClass}>{label}</label>
      <div className="control">
        <input
          className="input"
          onFocus={setFocused}
          onBlur={setBlurred}
          onChange={onChange}
          value={value}
          data-testid={testId}
          disabled={disabled}
          title={title}
        />
      </div>
    </div>
  );
};

export default FloatingLabelInput;

export type BaseInputProps = {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  testId?: string;
  disabled?: boolean;
  title?: string;
}
