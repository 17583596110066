import { APIBase, loginService } from '@agro1desenvolvimento/apis-js-package';
import { ENV } from '../utils';

const BACKEND_URL = {
  homologation: 'https://agroanalisa-homologacao.agro1.inf.br/api',
  production: 'https://agroanalisa.agro1.inf.br/api',
  test: 'https://agroanalisa-homologacao.test/api',
  development: 'http://localhost:3001/',
};

class APIAgroanalisaService extends APIBase {
  constructor() {
    super();

    const baseURL = BACKEND_URL[ENV];

    this.initialize({ baseURL });

    this.request.interceptors.response.use(undefined,
      (error) => {
        if (error.response.status === 403) {
          window.location.href = '#/403';
        } else {
          return Promise.reject(error);
        }
      });

    this.request.interceptors.request.use((request) => {
      request.headers = {
        ...loginService.requestHeaders(),
        ...request.headers,
      };

      return request;
    });
  }
}

export default new APIAgroanalisaService();
