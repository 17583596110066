import React from 'react';

const Forbidden: React.FC = () => (
  <div className="page-title">
    <h2 className="subtitle">Erro: 403</h2>
    <h3>Você não tem permissão para acessar este conteúdo!</h3>
  </div>
);

export default Forbidden;
