import React, { useRef, useState } from 'react';
import { Card, ConfirmPopup } from '@agro1desenvolvimento/react-components';
import { useIsCustomer } from '../../hooks';
import { BoardsService, NotificationsService } from '../../services';
import { Notifications, TableDropdownButton } from '..';

const CardHeader: React.FC<CardHeaderPropsType> = ({ id, title, itemId, onDeleted }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const isCustomer = useIsCustomer();

  const [dropdownOpened, setDropdownOpened] = useState('');

  const deleteNotification = () => NotificationsService.custom({
    message: <Notifications.Button
      text={<>Você tem certeza que deseja excluir <b>{title}</b>?</>}
      onClickYes={() => onConfirmDelete()}
    />,
  });

  const onConfirmDelete = async () => {
    try {
      const { status } = await BoardsService.remove(id, itemId);
      if (status === 200) onDeleted();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className="p-d-flex p-pl-4 p-pr-3 p-pt-3 item-header"
    >
      <ConfirmPopup
        target={containerRef.current || undefined}
        visible={deleteConfirmationVisible}
        onHide={() => setDeleteConfirmationVisible(false)}
        message="Realmente deseja excluir?"
        icon="pi pi-exclamation-triangle"
        accept={onConfirmDelete}
      />
      <p className="item-title">{title}</p>
      {!isCustomer && (
        <TableDropdownButton
          isActive={dropdownOpened === id}
          onClickOver={() => setDropdownOpened('')}
          onClickDropdownButton={() => setDropdownOpened(dropdownOpened ? '' : id)}
          redirectOnClickEdit={`/dashboards/board/${id}/editar/${itemId}`}
          onClickDelete={() => deleteNotification()}
        />
      )}
    </div>
  );
};

const DashboardItem: React.FC<DashboardItemPropsType> = ({
  id,
  itemId,
  children,
  title,
  onDeleted,
}) => (
  <Card
    className="p-d-flex p-flex-column dashboard-item"
    header={<CardHeader id={id} title={title} itemId={itemId} onDeleted={onDeleted} />}
  >
    {children}
  </Card>
);

interface DashboardItemPropsType {
  id: string,
  title: React.ReactNode,
  itemId: string,
  onDeleted: () => void,
}

interface CardHeaderPropsType {
  id: string,
  title: React.ReactNode,
  itemId: string,
  onDeleted: () => void,
}

export default DashboardItem;
