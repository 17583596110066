import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Toolbar } from '@agro1desenvolvimento/react-components';

const BoardPage: React.FC<DashboardsRouterType> = ({ history, match }) => {
  const ButtonNew = (
    <Link
      to={`/dashboards/board/${match.params.id}/nova`}
    >
      <Button
        label="Novo"
        aria-label="Novo"
        icon="pi pi-plus"
        className="p-mr-auto p-button-success"
      />
    </Link>
  );

  const ButtonBack = (
    <Button
      label="Voltar"
      aria-label="Voltar"
      icon="pi pi-arrow-left"
      className="p-mr-auto p-button-primary"
      onClick={() => history.push('/dashboards')}
    />
  );

  return (
    <Toolbar className="dashboard-toolbar" left={ButtonNew} right={ButtonBack} />
  );
};

export default BoardPage;

type DashboardsRouterType = RouteComponentProps<RouteParams>;

type RouteParams = {
  action?: 'novo' | 'nova' | 'editar',
  id?: string,
  subRoute?: 'board',
  idBoard: string,
};
