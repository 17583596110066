import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { InputNumber, Calendar } from '@agro1desenvolvimento/react-components';
import { join, map } from 'lodash';
import { Checkbox } from '../../../components';
import { hideLoader, showLoader } from '../../../components/Loader/loaderSlice';
import { MODAL_ANIMATION_DURATION, newDateWithoutUTC } from '../../../utils';
import {
  NotificationsService,
  ContractsService,
} from '../../../services';
import { RouteParams } from '../index';

const CreateEditPage: React.FC<PropsTypes> = ({ onSave, onClose }) => {
  const dispatch = useDispatch();
  const [area, setArea] = useState<number | undefined>();
  const [active, setActive] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const routeParams = useParams<RouteParams>();
  const saveEnabled = !!area && !!startDate && !!endDate && endDate >= startDate;

  const loadContract = async () => {
    const { customerId, id } = routeParams;

    try {
      if (!id) {
        cleanFields();
        return;
      }

      if (!customerId) throw new Error('Não está presente o ID do cliente');

      const contract = await ContractsService.findOneById({ customerId, contractId: id });
      setArea(contract.area);
      setActive(contract.active);
      setStartDate(newDateWithoutUTC(contract.startDate));
      setEndDate(newDateWithoutUTC(contract.endDate));

      if (newDateWithoutUTC(contract.endDate) < newDateWithoutUTC()) {
        setIsDisabled(true);
      }
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao carregar contrato para edição' });
      onClose();
    }
  };

  const loadData = async () => {
    dispatch(showLoader());

    try {
      await loadContract();
    } finally {
      dispatch(hideLoader());
    }
  };

  const onClickSave = async () => {
    if (!saveEnabled) return;

    const contract = { area, active, startDate, endDate };
    const { customerId, id } = routeParams;

    if (!customerId) throw new Error('Não foi possível carregar o id do cliente');

    try {
      if (id) {
        await ContractsService.update({ customerId, contractId: id }, contract);
      } else {
        await ContractsService.create(customerId, contract);
      }
      onSave();
      onClickCancel();
    } catch (error: any) {
      console.error(error);
      if (error.response.data) {
        const response = error?.response?.data;
        const errors = join(map(Object.keys(response), (key) => response[key]), ', ');

        const message = join(['Falha ao salvar contrato:', errors], ' ');
        NotificationsService.error({ message });
      } else {
        NotificationsService.error({ message: 'Falha ao salvar contrato' });
      }
    }
  };

  const cleanFields = () => {
    setTimeout(() => {
      setArea(undefined);
      setActive(true);
      setStartDate(undefined);
      setEndDate(undefined);
    }, MODAL_ANIMATION_DURATION);
  };

  const onClickCancel = () => {
    onClose();
    cleanFields();
  };

  useEffect(
    () => {
      setIsDisabled(false);
      if (routeParams.action) loadData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [routeParams.id],
  );

  return (
    <form
      className="create-edit-page create-edit-page-customer-contract"
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="field p-grid">
        <span className="p-float-label p-col-6 p-pr-3">
          <InputNumber
            id="area"
            data-testid="area"
            onChange={(e) => setArea(e.value)}
            value={area}
            className="number-input"
            maxFractionDigits={2}
            min={0}
            disabled={isDisabled}
          />
          <label className="p-pl-1" htmlFor="area">Área contratada</label>
        </span>
        <span className="p-col-6 p-ai-center p-pl-3">
          <Checkbox
            label="Ativo"
            onChange={() => setActive(!active)}
            value={active}
            disabled={isDisabled}
          />
        </span>
      </div>

      <div className="field p-grid">
        <span className="p-float-label p-col-6 p-pr-3">
          <Calendar
            inputId="start-date"
            data-testid="start-date"
            onChange={(e) => {
              const value = Array.isArray(e.value) ? e.value[0] : e.value;
              setStartDate(value);
            }}
            value={startDate}
            className="date-input"
            dateFormat="dd/mm/yy"
            disabled={routeParams.action === 'editar'}
          />
          <label className="p-pl-1" htmlFor="start-date">Data inicial</label>
        </span>

        <span className="p-float-label p-col-6 p-pl-3">
          <Calendar
            inputId="end-date"
            data-testid="end-date"
            onChange={(e) => {
              const value = Array.isArray(e.value) ? e.value[0] : e.value;
              setEndDate(value);
            }}
            value={endDate}
            className="date-input"
            dateFormat="dd/mm/yy"
            disabled={isDisabled}
          />
          <label className="p-pl-3" htmlFor="end-date">Data final</label>
        </span>
      </div>

      <div className="field is-grouped is-grouped-right">
        <p className="control">
          <button
            type="button"
            className="button is-light"
            onClick={onClickCancel}
          >
            Cancelar
          </button>
        </p>
        <p className="control">
          <button
            type="submit"
            className="button is-link"
            onClick={onClickSave}
            disabled={!saveEnabled}
          >
            Salvar
          </button>
        </p>
      </div>
    </form>
  );
};

export default CreateEditPage;

type PropsTypes = {
  onClose: () => void;
  onSave: () => void;
}
