import { useState } from 'react';
/**
 * @returns `[status, toggle, show, hide]`
 */
const useVisibility = (initial = false) => {
  const [visible, setVisible] = useState<boolean>(initial);

  const hide = () => setVisible(false);
  const show = () => setVisible(true);
  const toggle = () => (visible ? hide() : show());

  return [visible, toggle, show, hide] as [typeof visible, typeof toggle, typeof show, typeof hide];
};

export default useVisibility;
