import dayjs from 'dayjs';
import { DAYJS_FORMATS } from './constants';

export const dateFormat = (data: string) => dayjs(data).format(DAYJS_FORMATS.default);
export const dateAmericanFormat = (date: Date) => dayjs(date).format(DAYJS_FORMATS.american);
export const dateBrazilianFormat = (date: Date) => dayjs(date).format(DAYJS_FORMATS.brazilian);
export const formatWithoutTime = (data: string) => dayjs(data).format(DAYJS_FORMATS.withoutTime);

const iso860 = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;
export const isISODate = (date: string) => iso860.test(date);

export const newDateWithoutUTC = (date?: Date) => {
  let newDate = date ? new Date(date) : new Date();
  newDate = date ? new Date(newDate.getTime() + newDate.getTimezoneOffset() * 60000) : newDate;

  newDate.setHours(0, 0, 0, 0);

  return newDate;
};
