import { APIAgroanalisaService } from '.';
import { City } from '../@types/city';

class Cities {
  private baseUrl = 'v1/cities';

  async findOneById(id: string) {
    const { data } = await APIAgroanalisaService.get<City>(`${this.baseUrl}/${id}`);
    return data;
  }

  async find(q?: string) {
    const { data } = await APIAgroanalisaService.get<City[]>(this.baseUrl, { params: { q } });
    return data;
  }
}

export default new Cities();
