import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hideLoader, showLoader } from '../../components/Loader/loaderSlice';
import { CreateEditModal, Notifications, TableDropdownButton } from '../../components';
import ListTable, { ColumnProps } from '../../components/ListTable';
import CreateEditPage from './CreateEditPage';
import { IndexesService, NotificationsService } from '../../services';
import { Index } from '../../@types/index_';
import { useDataMatch } from '../../hooks';

const IndexPage: React.FC<PropTypes> = ({ match, history }) => {
  const dispatch = useDispatch();
  const [indexes, setIndexes] = useState<Index[]>([]);
  const [dropdownOpenned, setDropdownOpenned] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const isCreatingEditing = useDataMatch(match.params.action, ['novo', 'editar']);

  const deleteNotification = (id: string, description: string) => NotificationsService.custom({
    message: <Notifications.Button
      text={<>Você tem certeza que deseja excluir <b>{description}</b>?</>}
      onClickYes={() => onConfirmDelete(id)}
    />,
  });

  const onConfirmDelete = async (id: string) => {
    try {
      await IndexesService.remove(id);
      loadIndexes();
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao excluir Índice' });
    }
  };

  const columns: ColumnProps<Index>[] = [{
    field: 'description',
    header: 'Descrição',
  },
  {
    field: 'level',
    header: 'Nível',
  },
  {
    field: 'totalizer',
    header: 'Totalizador',
    body: (value: Index) => (value.totalizer && <FontAwesomeIcon icon="check" className="fa" />),
    className: 'indexes-totalizer',
  },
  {
    header: '',
    body: ({ uuid, description, id }: Index) => (
      <TableDropdownButton
        isActive={dropdownOpenned === uuid}
        onClickOver={() => setDropdownOpenned('')}
        onClickDropdownButton={() => setDropdownOpenned(dropdownOpenned ? '' : uuid)}
        redirectOnClickEdit={`/indices/editar/${id}`}
        onClickDelete={() => deleteNotification(id, description)}
      />
    ),
    sortable: false,
    className: 'options-column',
  }];

  const loadIndexes = async () => {
    dispatch(showLoader());
    try {
      setIndexes(await IndexesService.find(''));
    } catch (error) {
      NotificationsService.error({ message: 'Falha ao carregar Lista de Índices' });
      console.error(error);
    } finally {
      dispatch(hideLoader());
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { loadIndexes(); }, []);

  useEffect(() => {
    setModalVisible(isCreatingEditing);
  }, [isCreatingEditing]);

  return (
    <div className="indexes">
      <div className="page-title">
        <h1 className="title">Índices</h1>
      </div>
      <div>
        <div className="buttons is-right">
          <p className="control">
            <Link className="button is-link" to="/indices/novo">Adicionar Índice</Link>
          </p>
        </div>
        <CreateEditModal
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
          onClosed={() => history.push('/indices')}
          title={`${match.params.id ? 'Edição' : 'Criação'} de Índice`}
        >
          <CreateEditPage
            id={match.params.id}
            onClose={() => setModalVisible(false)}
            onSave={loadIndexes}
          />
        </CreateEditModal>
        <ListTable
          columns={columns}
          data={indexes}
          showUUID
        />
      </div>
    </div>
  );
};

export default IndexPage;

type PropTypes = RouteComponentProps<RouteParams>;

type RouteParams = {
  action?: 'novo' | 'editar',
  id?: string,
};
