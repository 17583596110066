import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Panel } from '@agro1desenvolvimento/react-components';
import { hideLoader, showLoader } from '../Loader/loaderSlice';
import { Variable } from '../../@types/variable';
import { FormulaFunction } from '../../@types/formula-function';
import { NotificationsService, FormulasService } from '../../services';

const FormulaInfo = () => {
  const dispatch = useDispatch();
  const [variables, setVariables] = useState<Variable[]>([]);
  const [functions, setFunctions] = useState<FormulaFunction[]>([]);

  const fetchData = async () => {
    dispatch(showLoader());

    try {
      const [variablesResult, functionsResult] = await Promise.all([
        FormulasService.variables(),
        FormulasService.functions(),
      ]);

      setVariables(variablesResult.sort());
      setFunctions(functionsResult);
    } catch (error) {
      NotificationsService.error({ message: 'Falha ao carregar dados da fórmula' });
      console.error(error);
    } finally {
      dispatch(hideLoader());
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchData(); }, []);

  return (
    <Panel id="formula-info" header="Fórmula (ajuda)" className="p-mb-5" toggleable collapsed>
      <div className="p-grid">
        <div className="p-col">
          <h1 className="p-mb-2 p-text-bold">Variáveis disponíveis</h1>
          <ul>
            {
              variables.map(({ description, uuid, name }) => (
                <li key={uuid}>
                  <strong>{ name }</strong> - {description}
                </li>
              ))
            }
          </ul>
        </div>

        <div className="p-col">
          <h1 className="p-mb-2 p-text-bold">Funções disponíveis</h1>
          <ul>
            {
              functions.map(({ description, name }) => (
                <li key={name}>
                  <strong>{ name }</strong> - {description}
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </Panel>
  );
};

export default FormulaInfo;
