import { library } from '@fortawesome/fontawesome-svg-core';
import * as solidSVGIcons from '@fortawesome/free-solid-svg-icons';

type iconsOnly<Icons extends Record<keyof any, any>> = SubType<
  Icons,
  Parameters<typeof library.add>[number]
>

const solidSVGIconsToAdd: (keyof iconsOnly<typeof solidSVGIcons>)[] = [
  'faBars',
  'faSeedling',
  'faMapMarkedAlt',
  'faAngleLeft',
  'faEllipsisV',
  'faEdit',
  'faTrashAlt',
  'faCheck',
  'faGlobeAmericas',
  'faFolderOpen',
];

const solidIcons = solidSVGIconsToAdd.map((iconKey) => solidSVGIcons[iconKey]);

library.add(...solidIcons);
