/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  RegionsService,
  CitiesService,
  NotificationsService,
  HarvestsService,
  CropsService,
  LocalStorage,
  LocalStorageItems,
  clearLocalStorageFilters,
} from '../../services';
import { hideLoader, showLoader } from '../Loader/loaderSlice';
import type { RootState } from '../../app/rootReducer';
import { Region } from '../../@types/region';
import { City } from '../../@types/city';
import { Harvest } from '../../@types/harvest';
import { Crop } from '../../@types/crop';

interface BoardFiltersState {
  harvests: Harvest[],
  cities: City[],
  regions: Region[],
  crops: Crop[],
  currentHarvest?: string,
  currentCity?: string,
  currentRegion?: string,
  currentCrop?: string,
  activeLoaders: number,
}

const initialState: BoardFiltersState = {
  harvests: [],
  regions: [],
  cities: [],
  crops: [],
  currentHarvest: LocalStorage.getItem('agroanalisa::currentHarvest') || undefined,
  currentCity: LocalStorage.getItem('agroanalisa::currentCity') || undefined,
  currentRegion: LocalStorage.getItem('agroanalisa::currentRegion') || undefined,
  currentCrop: LocalStorage.getItem('agroanalisa::currentCrop') || undefined,
  activeLoaders: 0,
};

const checkActiveLoadersAndHide = (state: BoardFiltersState) => {
  state.activeLoaders -= 1;
  if (state.activeLoaders === 0) {
    hideLoader();
  }
};

const showLoaderAndIncrement = (state: BoardFiltersState) => {
  showLoader();
  state.activeLoaders += 1;
};

const persistValue = (key: keyof LocalStorageItems, value: string) => {
  if (value) {
    LocalStorage.setItem(key, value);
  } else {
    LocalStorage.removeItem(key);
  }
};

export const fetchHarvests = createAsyncThunk(
  'boardFilters/Harvests',
  async () => {
    try {
      return await HarvestsService.find();
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao carregar lista de safras' });
    }
    return [];
  },
);

export const fetchCities = createAsyncThunk(
  'boardFilters/cities',
  async () => {
    try {
      return await CitiesService.find();
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao carregar lista de cidades' });
    }
    return [];
  },
);

export const fetchRegions = createAsyncThunk(
  'boardFilters/regions',
  async () => {
    try {
      return await RegionsService.find();
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao carregar lista de regiões' });
    }
    return [];
  },
);

export const fetchCrops = createAsyncThunk(
  'boardFilters/crops',
  async () => {
    try {
      return await CropsService.find();
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao carregar lista de atividades' });
    }
    return [];
  },
);

export const boardFiltersSlice = createSlice({
  name: 'boardFilters',
  initialState,
  reducers: {
    setCurrentHarvest: (state, action: PayloadAction<string>) => {
      persistValue('agroanalisa::currentHarvest', action.payload);
      state.currentHarvest = action.payload;
    },
    setCurrentCity: (state, action: PayloadAction<string>) => {
      persistValue('agroanalisa::currentCity', action.payload);
      state.currentCity = action.payload;
    },
    setCurrentRegion: (state, action: PayloadAction<string>) => {
      persistValue('agroanalisa::currentRegion', action.payload);
      state.currentRegion = action.payload;
    },
    setCurrentCrop: (state, action: PayloadAction<string>) => {
      persistValue('agroanalisa::currentCrop', action.payload);
      state.currentCrop = action.payload;
    },
    clearCurrentState: (state) => {
      clearLocalStorageFilters();
      state.currentHarvest = undefined;
      state.currentCity = undefined;
      state.currentRegion = undefined;
      state.currentCrop = undefined;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchHarvests.pending, (state) => {
      showLoaderAndIncrement(state);
    });
    builder.addCase(fetchHarvests.fulfilled, (state, action) => {
      state.harvests = action.payload;
      checkActiveLoadersAndHide(state);
    });
    builder.addCase(fetchHarvests.rejected, (state) => {
      checkActiveLoadersAndHide(state);
    });

    builder.addCase(fetchCities.pending, (state) => {
      showLoaderAndIncrement(state);
    });
    builder.addCase(fetchCities.fulfilled, (state, action) => {
      state.cities = action.payload;
      checkActiveLoadersAndHide(state);
    });
    builder.addCase(fetchCities.rejected, (state) => {
      checkActiveLoadersAndHide(state);
    });

    builder.addCase(fetchRegions.pending, (state) => {
      showLoaderAndIncrement(state);
    });
    builder.addCase(fetchRegions.fulfilled, (state, action) => {
      state.regions = action.payload;
      checkActiveLoadersAndHide(state);
    });
    builder.addCase(fetchRegions.rejected, (state) => {
      checkActiveLoadersAndHide(state);
    });

    builder.addCase(fetchCrops.pending, (state) => {
      showLoaderAndIncrement(state);
    });
    builder.addCase(fetchCrops.fulfilled, (state, action) => {
      state.crops = action.payload;
      checkActiveLoadersAndHide(state);
    });
    builder.addCase(fetchCrops.rejected, (state) => {
      checkActiveLoadersAndHide(state);
    });
  },
});

export const {
  setCurrentHarvest,
  setCurrentCity,
  setCurrentRegion,
  setCurrentCrop,
  clearCurrentState,
} = boardFiltersSlice.actions;

export const selectBoardFiltersState = (state: RootState) => state.boardFiltersReducer;

export default boardFiltersSlice.reducer;
