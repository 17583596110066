import { useMemo, useState } from 'react';
import classNames from 'classnames';

const useIsActive = (visible: boolean, onClosed: () => void) => {
  const [isActive, setIsActive] = useState(visible);

  const onExited = () => {
    setIsActive(false);
    onClosed();
  };

  const onEnter = () => setIsActive(true);

  return { isActive, onExited, onEnter };
};

const useModalClasses = (isActive: boolean, ...classExtra: string[]) => useMemo(
  () => classNames(
    { 'is-active': isActive },
    ...classExtra,
  ),
  [isActive, classExtra],
);

export { useIsActive, useModalClasses };
