import { APIAgroanalisaService } from '.';
import { Variable } from '../@types/variable';
import { FormulaFunction } from '../@types/formula-function';

class FormulasService {
  private baseUrl = 'v1/formulas';

  async variables() {
    const { data } = await APIAgroanalisaService.get<Variable[]>(`${this.baseUrl}/variables`);
    return data;
  }

  async functions() {
    const { data } = await APIAgroanalisaService
      .get<FormulaFunction[]>(`${this.baseUrl}/functions`);
    return data;
  }
}

export default new FormulasService();
