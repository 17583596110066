import { Chips, Dropdown } from '@agro1desenvolvimento/react-components/dist/primereact-components';
import { BinaryFilter, BinaryOperator, UnaryFilter, UnaryOperator } from '@cubejs-client/core';
import { FilterExtraFields, QueryBuilderRenderProps } from '@cubejs-client/react';
import React, { useMemo } from 'react';

const operators: Record<
  'string' | 'number' | 'time',
  { name: BinaryOperator | UnaryOperator, title: string }[]
> = {
  string: [
    { name: 'contains', title: 'Contem' },
    { name: 'notContains', title: 'Não contem' },
    { name: 'equals', title: 'Igual' },
    { name: 'notEquals', title: 'Diferente' },
    { name: 'set', title: 'Está definido' },
    { name: 'notSet', title: 'Não está definido' },
  ],
  number: [
    { name: 'equals', title: 'Igual' },
    { name: 'notEquals', title: 'Diferente' },
    { name: 'set', title: 'está definido' },
    { name: 'notSet', title: 'Não está definido' },
    { name: 'gt', title: '>' },
    { name: 'gte', title: '>=' },
    { name: 'lt', title: '<' },
    { name: 'lte', title: '<=' },
  ],
  time: [
    { name: 'equals', title: 'Igual' },
    { name: 'notEquals', title: 'Diferente' },
    { name: 'inDateRange', title: 'No intervalo' },
    { name: 'notInDateRange', title: 'Fora do intervalo' },
    { name: 'afterDate', title: 'Depois' },
    { name: 'beforeDate', title: 'Antes' },
  ],
};

export type BinareUnaryFilter = Omit<
  BinaryFilter | UnaryFilter,
  'dimension'
> & FilterExtraFields & { index: number }

const FilterGroup: React.FC<
  Pick<QueryBuilderRenderProps, 'updateFilters' | 'availableFilterMembers'> &
  { filters: BinareUnaryFilter[] }
> = ({ availableFilterMembers, filters, updateFilters }) => {
  const members = useMemo(
    () => availableFilterMembers.map((v) => v.members).flat(),
    [availableFilterMembers],
  );

  const onFilterChange = (
    value: string | undefined,
    filter: (typeof filters)[number],
  ) => {
    if (!value) {
      updateFilters.remove(filter);
      return;
    }

    updateFilters.update(
      filter,
      {
        ...filter,
        operator: filter.operator,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        dimension: members.find(({ name }) => name === value)!,
      },
    );
  };

  return (
    <>
      {filters.map((filter) => (
        <div key={filter.index} className="p-col p-md-6 filter-group">
          <div className="ml-1 p-d-grid p-md-d-flex p-ai-center">
            <Dropdown
              placeholder="Filtro"
              options={members}
              className="p-mr-1"
              filter
              onChange={({ value }) => onFilterChange(value, filter)}
              showClear
              optionLabel="title"
              optionValue="name"
              value={filter.member}
            />
            <Dropdown
              options={operators[filter.dimension.type as keyof typeof operators]}
              className="p-mx-1"
              onChange={({ value }) => (
                updateFilters.update(
                  filter,
                  { ...filter, operator: value },
                )
              )}
              optionLabel="title"
              optionValue="name"
              value={filter.operator}
            />
            <Chips
              allowDuplicate={false}
              placeholder="Valores"
              className="p-ml-1"
              onAdd={({ value }) => {
                updateFilters.update(
                  filter,
                  {
                    ...filter,
                    operator: filter.operator,
                    values: [...(filter.values || []), value],
                  },
                );
              }}
              onRemove={({ value }) => {
                updateFilters.update(
                  filter,
                  {
                    ...filter,
                    operator: filter.operator,
                    values: filter.values?.filter((v) => !value.includes(v)),
                  },
                );
              }}
              value={filter.values}
            />
          </div>
        </div>
      ))}

      <div className="p-col-2 ml-1 filter-group p-d-flex p-ai-center">
        <Dropdown
          className="dropdown-group"
          placeholder="Adicionar Filtro"
          options={members}
          filter
          onChange={({ value }) => (
            updateFilters.add({
              dimension: value,
              operator: operators[value.type as keyof typeof operators][0].name,
            })
          )}
          optionLabel="title"
        />
      </div>
    </>
  );
};

export default FilterGroup;
