import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../components/Loader/loaderSlice';
import { HarvestsService, NotificationsService } from '../../services';
import { Checkbox, FloatingLabelInput } from '../../components';
import { MODAL_ANIMATION_DURATION } from '../../utils';

const CreateEditPage: React.FC<PropsTypes> = ({ id, onClose, onSave }) => {
  const dispatch = useDispatch();
  const [description, setDescription] = useState('');
  const [current, setCurrent] = useState(false);
  const saveDisabled = useMemo(() => !description, [description]);

  const loadData = async () => {
    if (!id) {
      cleanFields();
      return;
    }

    dispatch(showLoader());
    try {
      const harvest = await HarvestsService.findOneById(id);
      setDescription(harvest.description);
      setCurrent(harvest.current);
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao carregar Safra para Edição' });
      onClose();
    } finally {
      dispatch(hideLoader());
    }
  };

  const onClickSave = async () => {
    const harvest = { description, current };

    try {
      if (!id) {
        await HarvestsService.create(harvest);
      } else {
        await HarvestsService.update(id, harvest);
      }
      onSave();
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao Salvar Safra' });
    } finally {
      onClickCancel();
    }
  };

  const cleanFields = () => {
    setTimeout(() => {
      setDescription('');
    }, MODAL_ANIMATION_DURATION);
  };

  const onClickCancel = () => {
    onClose();
    cleanFields();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { loadData(); }, [id]);

  return (
    <div className="create-edit-page">
      <FloatingLabelInput
        label="Descrição"
        onChange={(e) => setDescription(e.currentTarget.value)}
        value={description}
      />
      <Checkbox
        label="Atual"
        onChange={() => setCurrent(!current)}
        value={current}
      />
      <div className="field is-grouped is-grouped-right">
        <p className="control">
          <button className="button is-light" onClick={onClickCancel}>Cancelar</button>
        </p>
        <p className="control">
          <button
            className="button is-link"
            disabled={saveDisabled}
            onClick={onClickSave}
          >
            Salvar
          </button>
        </p>
      </div>
    </div>
  );
};

export default CreateEditPage;

type PropsTypes = {
  id?: string;
  onClose: () => void;
  onSave: () => void;
}
