import React, { useContext, useMemo } from 'react';
import { RouteComponentProps, withRouter, NavLink } from 'react-router-dom';
import { isRouteForbidden } from '../AgroanalizaRouters';
import { RoutesContext, useIsCustomer } from '../../hooks';
import Modal from '../Modal';

const NavbarSide: React.FC<NavbarSidePropTypes> = ({ visible }) => {
  const routes = useContext(RoutesContext);
  const isCustomer = useIsCustomer();
  const filteredRoutes = useMemo(() => routes.map(({ rootLink, roleAction, label }) => {
    if (isRouteForbidden(roleAction)) return <></>;

    if (!isCustomer && roleAction === 'agroanalisa.customer_panel') return;

    return (
      <li key={rootLink}>
        <NavLink to={rootLink} activeClassName="is-active">
          {label}
        </NavLink>
      </li>
    );
  }), [routes, isCustomer]);

  return (
    <div className="navbar-side" data-testid="navbar-side">
      <Modal
        visible={visible}
        contentAnimation="fade-left"
        className="left-sidebar-modal is-relative"
      >
        <aside className="menu">
          <ul className="menu-list">
            {filteredRoutes}
          </ul>
        </aside>
      </Modal>
    </div>
  );
};

interface NavbarSidePropTypes extends RouteComponentProps {
  visible: boolean,
}

export default withRouter(NavbarSide);
