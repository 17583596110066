import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import FarmsPage from './Farms';
import ContractsPage from './Contracts';
import CustomersPage from './CustomersPage';

export default (props: CustomerRouterType) => {
  // eslint-disable-next-line react/destructuring-assignment
  const { subRoute } = props.match.params;
  let Component;
  if (subRoute === 'propriedades') {
    Component = FarmsPage;
  } else if (subRoute === 'contratos') {
    Component = ContractsPage;
  } else {
    Component = CustomersPage;
  }
  return <Component {...props} />;
};

export type CustomerRouterType = RouteComponentProps<RouteParams>;

export type RouteParams = {
  action?: 'novo' | 'nova' | 'editar',
  id?: string,
  subRoute?: 'propriedades',
  customerId?: string,
};
