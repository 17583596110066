import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';

const Dropdown: React.FC<BaseDropdownProps> = ({
  children,
  button,
  isActive,
  openingAlignment,
  onClickOver = () => [],
  persistent,
}) => {
  const dropdownClass = useMemo(
    () => classNames(
      {
        'is-left': openingAlignment === 'left',
        'is-right': openingAlignment === 'right',
        'is-active': isActive,
      },
      'dropdown',
    ),
    [isActive, openingAlignment],
  );

  useEffect(() => {
    if (persistent) return;
    if (isActive) document.addEventListener('click', onClickOver);

    return () => document.removeEventListener('click', onClickOver);
  }, [isActive, onClickOver, persistent]);

  return (
    <div className={dropdownClass} data-testid="dropdown-actions">
      <div className="dropdown-trigger">
        {button}
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content" onClick={persistent ? undefined : onClickOver}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;

export type BaseDropdownProps = {
  button: React.ReactNode;
  isActive: boolean;
  openingAlignment: 'left' | 'right';
  onClickOver?: () => void;
  persistent?: boolean;
};
