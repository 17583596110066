import React, { useEffect, useState } from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { DashboardsService, NotificationsService } from '../../services';
import { Dashboard } from '../../@types/dashboard';
import Modal from '../Modal';

const ClientNavbarSide: React.FC<NavbarSidePropTypes> = ({ visible, setFirstBoardHandler }) => {
  const [dashboards, setDashboards] = useState<Dashboard[]>();

  useEffect(() => {
    const loadDashboards = async () => {
      try {
        setDashboards(await DashboardsService.find());
      } catch (error) {
        NotificationsService.error({ message: 'Falha ao carregar Lista de Dashboards' });
        console.error(error);
      }
    };

    loadDashboards();
  }, []);

  useEffect(() => {
    if (dashboards?.length) setFirstBoardHandler(dashboards[0].id);
  }, [dashboards, setFirstBoardHandler]);

  if (!dashboards?.length) return <></>;

  const dashboardsRoutes = dashboards.map((dashboard) => (
    <li key={dashboard.id}>
      <NavLink to={`/board/${dashboard.id}`} activeClassName="is-active">
        {dashboard.description}
      </NavLink>
    </li>
  ));

  return (
    <div className="navbar-side" data-testid="navbar-side">
      <Modal
        visible={visible}
        contentAnimation="fade-left"
        className="left-sidebar-modal is-relative"
      >
        <aside className="menu">
          <ul className="menu-list">
            {dashboardsRoutes}
          </ul>
        </aside>
      </Modal>
    </div>
  );
};

interface NavbarSidePropTypes extends RouteComponentProps {
  visible: boolean,
  setFirstBoardHandler: (boardId: string) => void;
}

export default withRouter(ClientNavbarSide);
