import { ReactNotificationOptions, store } from 'react-notifications-component';

class NotificationsService {
    notificationContent: ReactNotificationOptions = {
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    };

    success = (params: Partial<ReactNotificationOptions>) => {
      store.addNotification({
        ...this.notificationContent,
        ...params,
        type: 'success',
      });
    }

    error = (params: Partial<ReactNotificationOptions>) => {
      store.addNotification({
        ...this.notificationContent,
        ...params,
        type: 'danger',
        title: 'Erro',
      });
    }

    custom = (params: Partial<ReactNotificationOptions>) => {
      store.addNotification({
        ...this.notificationContent,
        ...params,
        // TODO: Types incorretos da bliblioteca
        // @ts-ignore
        type: 'button',
        dismiss: { duration: 0 },
      });
    }
}

export default new NotificationsService();
