import React, { ComponentPropsWithoutRef, ReactElement, ReactNode, useMemo } from 'react';
import { Column, DataTable } from '@agro1desenvolvimento/react-components';

const ListTable: ListTableType = ({
  columns,
  data,
  showUUID,
  alignUUID,
}) => {
  const internalColumns = useMemo(() => {
    const newColumns = [...columns];

    if (showUUID) {
      newColumns.unshift({
        field: 'uuid' as any,
        header: 'UUID',
        className: alignUUID ? 'uuid-column' : '',
      });
    }

    return newColumns;
  }, [columns, showUUID, alignUUID]);

  const getKey = (column: string | undefined, index: number) => `${column || index}_${index}`;

  return (
    <DataTable
      value={data}
      sortMode="multiple"
      removableSort
      emptyMessage="Nenhum registro encontrado."
    >
      {internalColumns.map((column, index) => (
        <Column key={getKey(column.field, index)} sortable {...column} />
      ))}
    </DataTable>
  );
};

export default ListTable;

type ListTableType = <T>(props: PropTypes<T>) => ReactElement

type RowDataAndKey<T> = {
  data: T[];
};

type ShowColumnUUID<T> = {
  data: (T & { uuid: string })[];
  showUUID?: boolean;
  alignUUID?: boolean;
} | {
  data: T[];
  showUUID?: never;
  alignUUID?: never;
}

type PropTypes<T> = {
  columns: ColumnProps<T>[];
} & RowDataAndKey<T> & ShowColumnUUID<T>

export type ColumnProps<T extends Record<string, any>> = {
  field?: keyof T;
  body?: (arg: T) => ReactNode;
} & ComponentPropsWithoutRef<typeof Column>;
