import { keysToSnakeCaseDeep } from '@agro1desenvolvimento/utils-js';
import { APIAgroanalisaService } from '.';

interface GenerateCsvParams {
  seasonUuid?: string;
  cropUuid?: string;
  customerIds?: string[];
}

class IndexSummaries {
  private baseUrl = 'v1/index_summaries';

  async generateCsv({ seasonUuid, cropUuid, customerIds }: GenerateCsvParams) {
    const response = await APIAgroanalisaService.get<Blob>(`${this.baseUrl}/csv`, {
      params: keysToSnakeCaseDeep({
        seasonUuid,
        cropUuid,
        customerIds,
      }),
      responseType: 'blob',
    });
    return new Blob([response.data], { type: 'text/csv' });
  }
}

export default new IndexSummaries();
