import React from 'react';
import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { Menubar } from '@agro1desenvolvimento/react-components';

const NavbarHeader: React.FC<PropsTypes> = ({ onClickBars, isOpen }) => {
  const itemsUser = [
    {
      label: loginService.currentUser?.name,
      className: 'p-justify-end name-user',
      items: [
        {
          label: 'Logout',
          className: 'logout',
          icon: 'pi pi-sign-out',
          command: () => loginService.logout(),
        },
      ],
    },
  ];
  const UserImg = () => (
    <img
      src={loginService.currentUser?.avatar}
      alt="Avatar do usuário"
      className="image is-32x32 is-rounded"
    />
  );
  const MenuBarEnd = () => (
    <Menubar
      model={itemsUser}
      className="navbar"
      end={UserImg}
    />
  );
  const items = [
    {
      icon: isOpen ? 'pi pi-angle-left' : 'pi pi-bars',
      command: () => onClickBars(),
      className: 'icon-close',
    },
    {
      label: 'Agroanalisa',
      className: 'navbar-title',
    },
  ];
  return (
    <Menubar
      model={items}
      end={MenuBarEnd}
      className="navbar"
      id="menubar"
    />
  );
};

interface PropsTypes {
  onClickBars: () => void,
  isOpen: boolean,
}

export default NavbarHeader;
