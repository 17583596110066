import React from 'react';
import Modal from '../Modal';

const Loader: React.FC<LoaderProps> = ({ visible }) => (
  <Modal.Root
    visible={visible}
    persistent={false}
    className="loader-modal"
  >
    <div className="loader-wrapper">
      <div className="loader" />
    </div>
  </Modal.Root>
);

export default Loader;

export type LoaderProps = {
  visible: boolean;
  onClose?: () => void;
}
