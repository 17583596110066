import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { dateBrazilianFormat, newDateWithoutUTC } from '../../../utils';
import { hideLoader, showLoader } from '../../../components/Loader/loaderSlice';
import { CreateEditModal, TableDropdownButton } from '../../../components';
import ListTable, { ColumnProps } from '../../../components/ListTable';
import CreateEditPage from './CreateEditPage';
import {
  CustomersService,
  NotificationsService,
  ContractsService,
} from '../../../services';
import { Contract } from '../../../@types/contract';
import { useDataMatch } from '../../../hooks';
import { CustomerRouterType } from '..';
import { Customer } from '../../../@types/customer';

const ContractsPage: React.FC<CustomerRouterType> = ({ match, history }) => {
  const dispatch = useDispatch();
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [customer, setCustomer] = useState<Customer>();
  const [dropdownOpened, setDropdownOpened] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const isCreatingEditing = useDataMatch(match.params.action, ['novo', 'editar']);

  const columns: ColumnProps<Contract>[] = [
    {
      field: 'area',
      header: 'Área contratada',
      body: (value: Contract) => (value.area ? Number(value.area).toLocaleString() : ''),
    },
    {
      field: 'startDate',
      header: 'Data inicial',
      body: (value: Contract) => dateBrazilianFormat(newDateWithoutUTC(value.startDate)),
    },
    {
      field: 'endDate',
      header: 'Data final',
      body: (value: Contract) => dateBrazilianFormat(newDateWithoutUTC(value.endDate)),
    },
    {
      field: 'active',
      header: 'Status',
      body: (value: Contract) => (value.active ? 'Ativo' : 'Inativo'),
    },
    {

      header: '',
      body: ({ id, uuid }: Contract) => (
        <TableDropdownButton
          isActive={dropdownOpened === uuid}
          onClickOver={() => setDropdownOpened('')}
          onClickDropdownButton={() => setDropdownOpened(dropdownOpened ? '' : uuid)}
          redirectOnClickEdit={`/clientes/${match.params.customerId}/contratos/editar/${id}`}
        />
      ),
      sortable: false,
      className: 'options-column',
    },
  ];
  const loadContracts = async () => {
    try {
      setContracts(await ContractsService.find(match.params.customerId || ''));
    } catch (error) {
      NotificationsService.error({ message: 'Falha ao carregar lista de contratos' });
    }
  };

  const refreshContracts = async () => {
    dispatch(showLoader());
    await loadContracts();
    dispatch(hideLoader());
  };

  const loadCustomer = async () => {
    try {
      setCustomer(await CustomersService.findOneById(match.params.customerId || ''));
    } catch (error) {
      NotificationsService.error({ message: 'Falha ao carregar lista de contratos' });
    }
  };

  const initializer = async () => {
    dispatch(showLoader());

    try {
      await Promise.all([
        loadCustomer(),
        loadContracts(),
      ]);
    } finally {
      dispatch(hideLoader());
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { initializer(); }, []);

  useEffect(() => {
    setModalVisible(isCreatingEditing);
  }, [isCreatingEditing]);

  return (
    <div className="contracts">
      <div className="page-title">
        <h1 className="title">{customer?.name} - Contratos</h1>
      </div>
      <div>
        <div className="buttons">
          <Link
            className="button is-link"
            to="/clientes"
          >
            Voltar
          </Link>
          <Link
            className="button is-link create-button"
            to={`/clientes/${match.params.customerId}/contratos/novo`}
          >
            Adicionar Contrato
          </Link>
        </div>
        <CreateEditModal
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
          onClosed={() => history.push(`/clientes/${match.params.customerId}/contratos`)}
          title={`${match.params.id ? 'Edição' : 'Criação'} de contratos`}
        >
          <CreateEditPage
            onClose={() => setModalVisible(false)}
            onSave={() => refreshContracts()}
          />
        </CreateEditModal>
        <ListTable
          columns={columns}
          data={contracts}
          showUUID
        />
      </div>
    </div>
  );
};

export default ContractsPage;
