import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../../components/Loader/loaderSlice';
import { CreateEditModal, Notifications, TableDropdownButton } from '../../../components';
import ListTable, { ColumnProps } from '../../../components/ListTable';
import CreateEditPage from './CreateEditPage';
import {
  CustomersService,
  NotificationsService,
  FarmsService,
} from '../../../services';
import { Farm } from '../../../@types/farm';
import { useDataMatch } from '../../../hooks';
import { CustomerRouterType } from '..';
import { Customer } from '../../../@types/customer';

const FarmsPage: React.FC<CustomerRouterType> = ({ match, history }) => {
  const dispatch = useDispatch();
  const [farm, setFarm] = useState<Farm[]>([]);
  const [customer, setCustomer] = useState<Customer>();
  const [dropdownOpened, setDropdownOpened] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const isCreatingEditing = useDataMatch(match.params.action, ['nova', 'editar']);

  const deleteNotification = (id: string, description: string) => NotificationsService.custom({
    message: <Notifications.Button
      text={<>Você tem certeza que deseja excluir <b>{description}</b>?</>}
      onClickYes={() => onConfirmDelete(id)}
    />,
  });

  const onConfirmDelete = async (id: string) => {
    const { customerId } = match.params;

    if (!customerId) return;

    try {
      await FarmsService.remove({ customerId, farmId: id });
      refreshFarms();
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao excluir Propriedade' });
    }
  };

  const columns: ColumnProps<Farm>[] = [
    {
      field: 'name',
      header: 'Nome',
    },
    {
      field: 'city',
      header: 'Cidade',
      body: (value: Farm) => value.city?.name,
    },
    {
      field: 'region',
      header: 'Região',
      body: (value: Farm) => value.region?.description,
    },
    {
      field: 'area',
      header: 'Área',
      body: (value: Farm) => (value.area ? Number(value.area).toLocaleString() : ''),
    },
    {

      header: '',
      body: ({ name, id, uuid }: Farm) => (
        <TableDropdownButton
          isActive={dropdownOpened === uuid}
          onClickOver={() => setDropdownOpened('')}
          onClickDropdownButton={() => setDropdownOpened(dropdownOpened ? '' : uuid)}
          redirectOnClickEdit={`/clientes/${match.params.customerId}/propriedades/editar/${id}`}
          onClickDelete={() => deleteNotification(id, name)}
        />
      ),
      sortable: false,
      className: 'options-column',
    },
  ];
  const loadFarms = async () => {
    try {
      setFarm(await FarmsService.find(match.params.customerId || ''));
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao carregar lista de propriedades' });
    }
  };

  const refreshFarms = async () => {
    dispatch(showLoader());
    await loadFarms();
    dispatch(hideLoader());
  };

  const loadCustomer = async () => {
    try {
      setCustomer(await CustomersService.findOneById(match.params.customerId || ''));
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao carregar lista de propriedades' });
    }
  };

  const initializer = async () => {
    dispatch(showLoader());

    try {
      await Promise.all([
        loadCustomer(),
        loadFarms(),
      ]);
    } finally {
      dispatch(hideLoader());
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { initializer(); }, []);

  useEffect(() => {
    setModalVisible(isCreatingEditing);
  }, [isCreatingEditing]);

  return (
    <div className="farms">
      <div className="page-title">
        <h1 className="title">{customer?.name} - Propriedades</h1>
      </div>
      <div>
        <div className="buttons">
          <Link
            className="button is-link"
            to="/clientes"
          >
            Voltar
          </Link>
          <Link
            className="button is-link create-button"
            to={`/clientes/${match.params.customerId}/propriedades/nova`}
          >
            Adicionar Propriedade
          </Link>
        </div>
        <CreateEditModal
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
          onClosed={() => history.push(`/clientes/${match.params.customerId}/propriedades`)}
          title={`${match.params.id ? 'Edição' : 'Criação'} de propriedades`}
        >
          <CreateEditPage
            onClose={() => setModalVisible(false)}
            onSave={() => refreshFarms()}
          />
        </CreateEditModal>
        <ListTable
          columns={columns}
          data={farm}
          showUUID
        />
      </div>
    </div>
  );
};

export default FarmsPage;
