import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { hideLoader, showLoader } from '../../components/Loader/loaderSlice';
import { CreateEditModal, Notifications, TableDropdownButton } from '../../components';
import ListTable, { ColumnProps } from '../../components/ListTable';
import CreateEditPage from './CreateEditPage';
import { CropsService, NotificationsService } from '../../services';
import { Crop } from '../../@types/crop';
import { useDataMatch } from '../../hooks';

const CropsPage: React.FC<PropTypes> = ({ match, history }) => {
  const dispatch = useDispatch();
  const [crops, setCrops] = useState<Crop[]>([]);
  const [dropdownOpened, setDropdownOpened] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const isCreatingEditing = useDataMatch(match.params.action, ['novo', 'editar']);

  const deleteNotification = (id: string, description: string) => NotificationsService.custom({
    message: <Notifications.Button
      text={<>Você tem certeza que deseja excluir <b>{description}</b>?</>}
      onClickYes={() => onConfirmDelete(id)}
    />,
  });

  const onConfirmDelete = async (id: string) => {
    try {
      await CropsService.remove(id);
      loadCrops();
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao excluir Atividade' });
    }
  };

  const columns: ColumnProps<Crop>[] = [{
    field: 'description',
    header: 'Descrição',
  },
  {
    header: '',
    body: ({ uuid, description, id }: Crop) => (
      <TableDropdownButton
        isActive={dropdownOpened === uuid}
        onClickOver={() => setDropdownOpened('')}
        onClickDropdownButton={() => setDropdownOpened(dropdownOpened ? '' : uuid)}
        redirectOnClickEdit={`/atividades/editar/${id}`}
        onClickDelete={() => deleteNotification(id, description)}
      />
    ),
    sortable: false,
    className: 'options-column',
  }];

  const loadCrops = async () => {
    dispatch(showLoader());
    try {
      setCrops(await CropsService.find(''));
    } catch (error) {
      NotificationsService.error({ message: 'Falha ao carregar Lista de Atividades' });
      console.error(error);
    } finally {
      dispatch(hideLoader());
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { loadCrops(); }, []);

  useEffect(() => {
    setModalVisible(isCreatingEditing);
  }, [isCreatingEditing]);

  return (
    <div className="crops">
      <div className="page-title">
        <h1 className="title">Atividades</h1>
      </div>
      <div>
        <div className="buttons is-right">
          <p className="control">
            <Link className="button is-link" to="/atividades/novo">Adicionar Atividade</Link>
          </p>
        </div>
        <CreateEditModal
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
          onClosed={() => history.push('/atividades')}
          title={`${match.params.id ? 'Edição' : 'Criação'} de Atividade`}
        >
          <CreateEditPage
            id={match.params.id}
            onClose={() => setModalVisible(false)}
            onSave={loadCrops}
          />
        </CreateEditModal>
        <ListTable
          columns={columns}
          data={crops}
          showUUID
        />
      </div>
    </div>
  );
};

export default CropsPage;

type PropTypes = RouteComponentProps<RouteParams>;

type RouteParams = {
  action?: 'novo' | 'editar',
  id?: string,
};
