import React from 'react';
import { Link } from 'react-router-dom';

const TableLink: React.FC<TableLinkProps> = ({
  description,
  redirectOnClickLink,
}) => (
  <Link className="table-link" to={redirectOnClickLink}>
    {description}
  </Link>
);

export default TableLink;

export type TableLinkProps = {
  description: string,
  redirectOnClickLink: string;
};
