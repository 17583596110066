import cubejs, { CubejsApi } from '@cubejs-client/core';
import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { ENV } from '../utils';

class CubeJSService {
  readonly apiUrl = ENV === 'production'
    ? 'https://agroanalisa.agro1.inf.br/cubejs'
    : 'https://agroanalisa-homologacao.agro1.inf.br/cubejs';

  #api: CubejsApi | null = null

  initialize() {
    this.#api = cubejs(loginService.token || '', { apiUrl: `${this.apiUrl}/cubejs-api/v1` });
  }

  get api() {
    if (this.#api) return this.#api;
    throw new Error('CubeJSService don`t initialized');
  }
}

export default new CubeJSService();
