import React, { useMemo } from 'react';
import classNames from 'classnames';

const SelectDropdown: React.FC<SelectDropdownProps> = ({
  children,
  label,
  onChange,
  value,
  required,
  id = label,
}) => {
  const SelectDropdownClass = useMemo(
    () => classNames(
      { 'is-active': value },
      'label',
    ),
    [value],
  );

  return (
    <div className="field has-floating-label">
      <label
        className={SelectDropdownClass}
        htmlFor={id}
        data-testid="active-value"
      >
        {label}
      </label>
      <div className="control">
        <div className="select">
          <select
            id={id}
            onChange={onChange}
            value={value}
          >
            <option disabled={required} value="">{label}</option>
            {children}
          </select>
        </div>
      </div>
    </div>
  );
};

export default SelectDropdown;

export type SelectDropdownProps = {
  label: string;
  value: string;
  required?: boolean;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  id?: string;
}
