import { useEffect, useState } from 'react';
import { Query, Filter } from '@cubejs-client/core';
import { useCubeQuery, UseCubeQueryOptions } from '@cubejs-client/react';
import { isArray, isEmpty, isString } from 'lodash';

export interface QueryVariables {
  $CUSTOMERS: string | undefined,
  $REGIAO: string | undefined,
  $CIDADE: string | undefined
}

const parseFilters = (
  filters: Filter[] = [],
  variables: QueryVariables,
): Filter[] => {
  const replaceVariables = (values: string[]) => (
    values.flatMap((value) => {
      if (value in variables) return variables[value as keyof QueryVariables];
      return value;
    }).filter(isString)
  );

  return filters.reduce<Filter[]>((acc, { ...filter }) => {
    if (!('values' in filter)) {
      return acc;
    }

    if (isArray(filter.values)) {
      // eslint-disable-next-line no-param-reassign
      filter.values = replaceVariables(filter.values);
    }
    if (!isEmpty(filter.values)) acc.push(filter);
    return acc;
  }, []);
};

const useCustomizedCubeQuery = (
  query: Query,
  variables: QueryVariables,
  options?: UseCubeQueryOptions,
) => {
  const [parsedQuery, setParsedQuery] = useState<Query>({});
  const queryResult = useCubeQuery(parsedQuery, options);

  useEffect(() => {
    setParsedQuery({
      ...query,
      filters: parseFilters(query.filters, variables),
    });
  }, [variables, query]);

  return queryResult;
};

export default useCustomizedCubeQuery;
