import React, { useState, useEffect, useMemo } from 'react';
import { Dropdown, InputText } from '@agro1desenvolvimento/react-components';
import { useDispatch } from 'react-redux';
import { join, map } from 'lodash';
import { useParams } from 'react-router';
import { hideLoader, showLoader } from '../../components/Loader/loaderSlice';
import { MODAL_ANIMATION_DURATION } from '../../utils';
import {
  NotificationsService,
  CustomersService,
  RegionsService,
  CitiesService,
} from '../../services';
import { City } from '../../@types/city';
import { Region } from '../../@types/region';
import { RouteParams } from '.';

const CreateEditPage: React.FC<PropsTypes> = ({ id, onSave, onClose }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [city, setCity] = useState<City>();
  const [cities, setCities] = useState<City[]>([]);
  const [email, setEmail] = useState('');
  const [regions, setRegions] = useState<Region[]>([]);
  const [region, setRegion] = useState<Region>();
  const routeParams = useParams<RouteParams>();
  const saveDisabled = useMemo(
    () => [!!name, !!city?.name, !!email, !!region?.description].every(Boolean),
    [name, city, region, email],
  );

  const loadCustomer = async () => {
    if (!id) {
      cleanFields();
      return;
    }

    try {
      const customer = await CustomersService.findOneById(id);
      setName(customer.name);
      setEmail(customer.email);
      setCity(customer.city);
      setRegion(customer.region);
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao carregar Cliente para edição' });
      console.error(error);
      onClose();
    }
  };

  const loadRegions = async () => {
    if (regions.length) return;

    try {
      setRegions(await RegionsService.find(''));
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao carregar regiões' });
      console.error(error);
    }
  };

  const loadCities = async () => {
    if (cities.length) return;

    try {
      setCities(await CitiesService.find(''));
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao carregar cidades' });
      console.error(error);
    }
  };

  const loadDate = async () => {
    dispatch(showLoader());
    try {
      await Promise.all([
        loadCustomer(),
        loadRegions(),
        loadCities(),
      ]);
    } finally {
      dispatch(hideLoader());
    }
  };

  const onFormSubmit = async () => {
    if (!city || !region) return;

    const customer = {
      name,
      email,
      cityUuid: city.uuid,
      regionUuid: region.uuid,
    };

    try {
      if (!id) {
        await CustomersService.create(customer);
      } else {
        await CustomersService.update(id, customer);
      }
      onSave();
      onClickCancel();
    } catch (error: any) {
      console.error(error);

      if (error?.response?.status === 400 && error.response.data) {
        const response = error?.response?.data;
        const errors = join(map(Object.keys(response), (key) => response[key]), ', ');

        const message = join(['Falha ao salvar cliente:', errors], ' ');
        NotificationsService.error({ message });
      } else {
        NotificationsService.error({ message: 'Falha ao salvar cliente' });
      }
    }
  };

  const cleanFields = () => {
    setTimeout(() => {
      setName('');
      setEmail('');
      setCity({
        name: '',
        uf: '',
        uuid: '',
        id: '',
      });
      setRegion({
        description: '',
        id: '',
        type: 'region',
        uf: '',
        uuid: '',
      });
    }, MODAL_ANIMATION_DURATION);
  };

  const onClickCancel = () => {
    onClose();
    cleanFields();
  };

  useEffect(
    () => {
      if (routeParams.action) loadDate();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, routeParams.action],
  );

  return (
    <form
      className="create-edit-page"
      onSubmit={(e) => {
        e.preventDefault();
        onFormSubmit();
      }}
    >
      <span className="p-float-label text-input-container">
        <InputText
          id="name"
          data-testid="nome"
          onChange={(e) => setName(e.currentTarget.value)}
          value={name}
          className="text-input"
        />
        <label htmlFor="name">Nome do Cliente</label>
      </span>
      <span className="p-float-label text-input-container">
        <InputText
          id="email"
          data-testid="email"
          onChange={(e) => setEmail(e.currentTarget.value)}
          value={email}
          className="text-input"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
          title="Por favor digite um email valido, por exemplo: 'exemplo@email.com'"
        />
        <label htmlFor="email">Email</label>
      </span>
      <Dropdown
        value={city}
        options={cities}
        onChange={(e) => setCity(e.value)}
        placeholder="Selecione uma cidade"
        filter
        showClear
        optionLabel="name"
        filterBy="name"
        className="dropdown-input"
        emptyFilterMessage="Nenhuma cidade encontrada"
      />
      <Dropdown
        value={region}
        options={regions}
        onChange={(e) => setRegion(e.value)}
        placeholder="Selecione uma região"
        filter
        showClear
        optionLabel="description"
        filterBy="description"
        className="dropdown-input"
        emptyFilterMessage="Nenhuma região encontrada"
      />
      <div className="field is-grouped is-grouped-right">
        <p className="control">
          <button
            type="button"
            className="button is-light"
            onClick={onClickCancel}
          >
            Cancelar
          </button>
        </p>
        <p className="control">
          <button
            type="submit"
            className="button is-link"
            disabled={!saveDisabled}
          >
            Salvar
          </button>
        </p>
      </div>
    </form>
  );
};

export default CreateEditPage;

type PropsTypes = {
  id?: string;
  onClose: () => void;
  onSave: () => void;
}
