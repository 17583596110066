import React, { useEffect, useState } from 'react';
import { Button, Toolbar } from '@agro1desenvolvimento/react-components';
import { Link, withRouter } from 'react-router-dom';
import { VizState } from '@cubejs-client/react';
import { ChartType, Filter } from '@cubejs-client/core';
import ExploreQueryBuilder from '../../../components/Dashboards/ExploreQueryBuilder';
import DialogSaveChart from '../../../components/Dashboards/DialogSaveChart';
import { Board, ExtraVizState } from '../../../@types/board';
import { BoardsService } from '../../../services';
import { CrudCreateUpdate } from '../../../utils';

const CHART_TYPE: ChartType = 'bar';

const FILTERS: Filter[] = [
  {
    member: 'IndexSummaries.indexDescription',
    operator: 'contains',
    values: [],
  },
  {
    member: 'Customers.regionId',
    operator: 'equals',
    values: ['$REGIAO'],
  },
  {
    member: 'Customers.cityId',
    operator: 'equals',
    values: ['$CIDADE'],
  },
  {
    member: 'IndexSummaries.seasonId',
    operator: 'equals',
    values: ['$SAFRA'],
  },
  {
    member: 'IndexSummaries.cropId',
    operator: 'equals',
    values: ['$ATIVIDADE'],
  },
];

const NEW_BOARD_PAYLOAD = {
  chartType: CHART_TYPE,
  pivotConfig: {
    x: ['IndexSummaries.farmName'],
    y: ['measures'],
    fillMissingDates: true,
  },
  query: {
    measures: ['IndexSummaries.value'],
    timeDimensions: [],
    order: [],
    dimensions: ['IndexSummaries.farmName'],
    filters: FILTERS,
  },
};

const ToolBarLeft: React.FC<
  { onSavePress: () => void, id: string, idBoard?: string, isValid: boolean }
> = ({ onSavePress, id, idBoard, isValid }) => (
  <>
    <Link to={`/dashboards/board/${id}`}>
      <Button
        label="Voltar"
        aria-label="Voltar"
        icon="pi pi-arrow-left"
        className="p-mr-auto p-button-primary"
      />
    </Link>
    <Button
      disabled={!isValid}
      icon={`pi ${idBoard ? 'pi-pencil' : 'pi-plus'}`}
      key="button"
      className="p-button-success p-ml-2"
      onClick={onSavePress}
      label={idBoard ? 'Atualizar' : 'Adicionar'}
      aria-label={idBoard ? 'Atualizar' : 'Adicionar'}
    />
  </>
);

const CreateEditDashboardPage = withRouter(({ history, match }) => {
  const { id } = match.params;
  const { idBoard } = match.params ?? '';
  const [loading, setLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);

  const [dashboard, setDashboard] = useState<CrudCreateUpdate<Board>>({
    layout: { h: 0, w: 0, x: 0, y: 0 },
    payload: NEW_BOARD_PAYLOAD,
    extraPayload: {},
    titulo: 'Novo dashboard',
  });

  useEffect(() => {
    const fetchDashboard = async () => {
      try {
        if (!idBoard) return;
        setLoading(true);

        const dash = await BoardsService.findOneById(id, idBoard);
        setDashboard(dash);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboard();
  }, [id, idBoard]);

  const onVizStateChanged = (newVizState: VizState) => {
    const isDifferentChartType = newVizState.chartType !== dashboard.payload.chartType;

    setDashboard(
      (futureDashboard) => ({
        ...futureDashboard,
        payload: newVizState,
        extraPayload: isDifferentChartType ? {} : futureDashboard.extraPayload,
      }),
    );
  };

  const updateExtraVizState = (newExtraVizState: ExtraVizState) => {
    setDashboard(
      (prevDashboard) => ({ ...prevDashboard, extraPayload: newExtraVizState }),
    );
  };

  if (loading) return <></>;

  return (
    <>
      <DialogSaveChart
        afterSave={() => history.push(`/dashboards/board/${id}`)}
        id={id}
        idBoard={idBoard}
        item={dashboard}
        onHide={() => setDialogVisible(false)}
        visible={dialogVisible}
      />
      <Toolbar
        left={(
          <ToolBarLeft
            id={id}
            idBoard={idBoard}
            onSavePress={() => setDialogVisible(true)}
            isValid={isValid}
          />
        )}
        className="p-mb-3"
      />

      <ExploreQueryBuilder
        vizState={dashboard.payload}
        extraVizState={dashboard.extraPayload}
        updateExtraVizState={updateExtraVizState}
        onVizStateChanged={onVizStateChanged}
        setIsValid={setIsValid}
      />
    </>
  );
});

export default CreateEditDashboardPage;
