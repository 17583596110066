import { Dropdown } from '@agro1desenvolvimento/react-components/dist/primereact-components';
import { QueryBuilderRenderProps } from '@cubejs-client/react';
import { first } from 'lodash';
import React, { useMemo } from 'react';

const dateRanges = [
  {
    title: 'Todo período',
    value: '',
  },
  {
    title: 'Hoje',
    value: 'Today',
  },
  {
    title: 'Ontem',
    value: 'Yesterday',
  },
  {
    title: 'Esta semana',
    value: 'This week',
  },
  {
    title: 'Este mês',
    value: 'This month',
  },
  {
    title: 'Este trimestre',
    value: 'This quarter',
  },
  {
    title: 'Este ano',
    value: 'This year',
  },
  {
    title: 'Últimos 7 dias',
    value: 'from 7 days ago to now',
  },
  {
    title: 'Últimos 30 dias',
    value: 'from 30 days ago to now',
  },
  {
    title: 'Última semana',
    value: 'Last week',
  },
  {
    title: 'Último mês',
    value: 'Last month',
  },
  {
    title: 'Último trimestre',
    value: 'Last quarter',
  },
  {
    title: 'Último ano',
    value: 'Last year',
  },
];

const granularities = [
  { value: 'hour', title: 'Hora' },
  { value: 'day', title: 'Dia' },
  { value: 'week', title: 'Semana' },
  { value: 'month', title: 'Mês' },
  { value: 'year', title: 'Ano' },
];

const TimeGroup: React.FC<
  Pick<QueryBuilderRenderProps,
  'timeDimensions' | 'updateTimeDimensions' | 'availableTimeDimensions'>
> = ({ availableTimeDimensions, timeDimensions, updateTimeDimensions }) => {
  const member = useMemo(() => first(timeDimensions), [timeDimensions]);

  const dimension = useMemo(
    () => member && availableTimeDimensions.find(({ name }) => member.dimension.name === name),
    [availableTimeDimensions, member],
  );

  return (
    <div className="p-col time-group mt-1 ml-1">
      <div className="content">
        <Dropdown
          placeholder="Intervalo"
          showClear
          filter
          options={availableTimeDimensions}
          optionLabel="title"
          onChange={({ value }) => {
            if (!value) {
              if (member) updateTimeDimensions.remove(member);
              return;
            }

            if (member) {
              updateTimeDimensions.update(member, {
                ...member,
                dimension: value,
              });
            } else {
              updateTimeDimensions.add({
                granularity: 'day',
                dimension: value,
              });
            }
          }}
          value={dimension}
        />

        {member && (
          <>
            <b className="p-mx-2 p-my-auto">Para</b>

            <Dropdown
              options={dateRanges}
              onChange={({ value }) => {
                updateTimeDimensions.update(member, { ...member, dateRange: value });
              }}
              value={member?.dateRange || ''}
              optionValue="value"
              optionLabel="title"
              placeholder="Todo o período"
            />

            <b className="p-mx-2 p-my-auto">Por</b>

            <Dropdown
              options={granularities}
              onChange={({ value }) => {
                updateTimeDimensions.update(member, { ...member, granularity: value });
              }}
              value={member?.granularity}
              optionValue="value"
              optionLabel="title"
            />
          </>
        )}

      </div>
    </div>
  );
};

export default TimeGroup;
