import 'primeicons/primeicons.css';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactNotification from 'react-notifications-component';
import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { CubeProvider } from '@cubejs-client/react';
import { selectLoaderState } from './components/Loader/loaderSlice';
import Loader from './components/Loader';
import { NotificationsService, CubeJSService } from './services';
import { AgroanalisaNavbar } from './components';
import { ENV } from './utils';

const App = () => {
  const [loadingUser, setLoadingUser] = useState(true);
  const { visible: loaderVisible } = useSelector(selectLoaderState);

  const initializer = async () => {
    setLoadingUser(true);
    try {
      await loginService.initializeByEnvironment('agroanalisa', ENV);
      if (!loginService.isLoggedIn) loginService.redirectInvalidUser();

      CubeJSService.initialize();
    } catch (error: any) {
      if (error?.response?.status === 401) {
        loginService.redirectInvalidUser();
        return;
      }

      NotificationsService.error(
        { message: 'Não foi possível carregar o seu usuário, por favor tente novamente.' },
      );

      console.error(error);
    } finally {
      setLoadingUser(false);
    }
  };

  useEffect(() => {
    initializer();
  }, []);

  if (loadingUser) return null;

  return (
    <div className="app">
      <CubeProvider cubejsApi={CubeJSService.api}>
        <ReactNotification
          // TODO: Types incorretos da biblioteca
          types={[
            // @ts-expect-error
            {
              htmlClasses: ['notification-button'],
              name: 'button',
            },
          ]}
          isMobile={false}
        />
        <AgroanalisaNavbar />
        <Loader visible={loadingUser || loaderVisible} />
      </CubeProvider>
    </div>
  );
};
export default App;
