import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { hideLoader, showLoader } from '../../components/Loader/loaderSlice';
import { CreateEditModal, TableDropdownButton, Notifications, TableLink } from '../../components';
import { useDataMatch } from '../../hooks';
import ListTable, { ColumnProps } from '../../components/ListTable';
import { Dashboard } from '../../@types/dashboard';
import { DashboardsService, NotificationsService } from '../../services';
import CreateEditPage from './CreateEditPage';
import { DashboardsRouterType } from '.';

const DashboardsPage: React.FC<DashboardsRouterType> = ({ match, history }) => {
  const dispatch = useDispatch();
  const [dashboards, setDashboards] = useState<Dashboard[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [dropdownOpened, setDropdownOpened] = useState('');
  const isCreatingEditing = useDataMatch(match.params.action, ['novo', 'editar']);

  const deleteNotification = (id: string, description: string) => NotificationsService.custom({
    message: <Notifications.Button
      text={<>Você tem certeza que deseja excluir <b>{description}</b>?</>}
      onClickYes={() => onConfirmDelete(id)}
    />,
  });

  const onConfirmDelete = async (id: string) => {
    try {
      await DashboardsService.remove(id);
      loadDashboards();
    } catch (error) {
      console.error(error);
      NotificationsService.error({ message: 'Falha ao excluir Dashboard' });
    }
  };

  const columns: ColumnProps<Dashboard>[] = [{
    header: 'Dashboards',
    body: ({ description, id }: Dashboard) => (
      <TableLink
        description={description}
        redirectOnClickLink={`/dashboards/board/${id}`}
      />
    ),
  },
  {
    header: '',
    body: ({ uuid, description, id }: Dashboard) => (
      <TableDropdownButton
        isActive={dropdownOpened === uuid}
        onClickOver={() => setDropdownOpened('')}
        onClickDropdownButton={() => setDropdownOpened(dropdownOpened ? '' : uuid)}
        redirectOnClickEdit={`/dashboards/editar/${id}`}
        onClickDelete={() => deleteNotification(id, description)}
      />
    ),
    sortable: false,
    className: 'options-column',
  }];

  const loadDashboards = async () => {
    dispatch(showLoader());
    try {
      setDashboards(await DashboardsService.find());
    } catch (error) {
      NotificationsService.error({ message: 'Falha ao carregar Lista de Dashboards' });
      console.error(error);
    } finally {
      dispatch(hideLoader());
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { loadDashboards(); }, []);

  useEffect(() => {
    setModalVisible(isCreatingEditing);
  }, [isCreatingEditing]);

  return (
    <div>
      <div className="page-title">
        <h1 className="title">Dashboards</h1>
      </div>
      <div>
        <div className="buttons is-right">
          <p className="control">
            <Link className="button is-link" to="/dashboards/novo">Adicionar Dashboard</Link>
          </p>
        </div>
        <CreateEditModal
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
          onClosed={() => history.push('/dashboards')}
          title={`${match.params.id ? 'Edição' : 'Criação'} de Dashboard`}
        >
          <CreateEditPage
            id={match.params.id}
            onClose={() => setModalVisible(false)}
            onSave={loadDashboards}
          />
        </CreateEditModal>
        <ListTable
          columns={columns}
          data={dashboards}
        />
      </div>
    </div>
  );
};

export default DashboardsPage;
