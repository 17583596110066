import React from 'react';

const NotFound: React.FC = () => (
  <div className="page-title">
    <h2 className="subtitle">Erro: 404</h2>
    <h3>Está página não existe no sistema!</h3>
  </div>
);

export default NotFound;
