import React, { FC, useMemo } from 'react';
import { VizState } from '@cubejs-client/react';
import { DataTable, ProgressSpinner, Column } from '@agro1desenvolvimento/react-components';
import {
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
  LineChart,
  Line,
  LabelList,
} from 'recharts';
import { ChartType, PivotConfig, ResultSet } from '@cubejs-client/core';
import { useSelector } from 'react-redux';
import { formatNumber } from '@agro1desenvolvimento/utils-js';
import { selectBoardFiltersState } from '../BoardFilters/boardFiltersSlice';
import { useCustomizedCubeQuery } from '../../hooks';
import { ExtraVizState } from '../../@types/board';
import { LIST_LABEL_FONT_SIZE } from '../../utils';

const format = (value: any) => {
  if (!Number.isNaN(+value)) return formatNumber(value, { useGrouping: true });

  return value;
};

const displayValue = (extraVizState: ExtraVizState, eValue: any, value: any) => {
  if (!extraVizState.displayValuesOnBarGraph) return '';
  const formattedValue = format(value);

  return formattedValue;
};

const colors = ['#00b894', '#6c5ce7', '#0984e3', '#008EA8', '#1266DE'];
const EMPHASIS_COLOR = '#e74c3c';
const LENGTH_TO_EMPHASIS = 3;

const getColor = (index: number) => colors[index % colors.length];

const LABEL_ANGLE = 45;

interface CartesianChartPropsType {
  ChartComponent: React.ComponentType<{ data?: any }>
  resultSet: ResultSet
}

const CartesianChart: React.FC<CartesianChartPropsType> = ({
  resultSet,
  children,
  ChartComponent,
}) => (
  <ResponsiveContainer width="100%" height={350}>
    <ChartComponent data={resultSet.chartPivot()}>
      <XAxis dataKey="x" tickFormatter={format} />
      <YAxis width={90} padding={{ top: 60 }} tickFormatter={format} />
      <CartesianGrid />
      {children}
      <Legend />
      <Tooltip
        formatter={format}
        labelFormatter={format}
        cursor={{ opacity: 0.25 }}
      />
    </ChartComponent>
  </ResponsiveContainer>
);

interface ChartPropsTypes {
  pivotConfig: PivotConfig,
  resultSet: ResultSet<any>,
  extraVizState: ExtraVizState,
}

const TypeToChartComponent: Record<ChartType, React.FC<ChartPropsTypes>> = {
  line: ({ resultSet }) => (
    <CartesianChart resultSet={resultSet} ChartComponent={LineChart}>
      {resultSet.seriesNames().map((series, i) => (
        <Line
          key={series.key}
          dataKey={series.key}
          name={series.title}
          stroke={getColor(i)}
        />
      ))}
    </CartesianChart>
  ),
  bar: ({ resultSet, extraVizState }) => (
    <CartesianChart
      resultSet={resultSet}
      ChartComponent={BarChart}
    >
      {resultSet.seriesNames().map((series, i) => (
        <Bar
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.title}
          fill={getColor(i)}
        >
          {
            resultSet.rawData().map((data) => {
              if (!('IndexSummaries.farmName' in data)) return;
              if (data['IndexSummaries.farmName'].length > LENGTH_TO_EMPHASIS) {
                return (
                  <Cell
                    key={data['IndexSummaries.farmName']}
                    fill={getColor(i)}
                    stroke={EMPHASIS_COLOR}
                    strokeWidth={3}
                  />
                );
              }
              return (
                <Cell key={data['IndexSummaries.farmName']} fill={getColor(i)} stroke="#FFF" />
              );
            })
          }
          <LabelList
            valueAccessor={(value: any) => displayValue(extraVizState, value, value[series.key])}
            fontSize={LIST_LABEL_FONT_SIZE}
            position="center"
            angle={LABEL_ANGLE}
            stroke="#000"
            strokeWidth={0.5}
          />
        </Bar>
      ))}
    </CartesianChart>
  ),
  area: ({ resultSet }) => (
    <CartesianChart resultSet={resultSet} ChartComponent={AreaChart}>
      {resultSet.seriesNames().map((series, i) => (
        <Area
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.title}
          stroke={getColor(i)}
          fill={getColor(i)}
        />
      ))}
    </CartesianChart>
  ),
  pie: ({ resultSet }) => (
    <ResponsiveContainer width="100%" height={350}>
      <PieChart>
        <Pie
          isAnimationActive={false}
          data={resultSet.chartPivot()}
          nameKey="x"
          dataKey={resultSet.seriesNames()[0]?.key}
          fill="#8884d8"
        >
          {resultSet.rawData().map((data, index) => {
            if (!('IndexSummaries.farmName' in data)) return;
            if (data['IndexSummaries.farmName'].length > 3) {
              return (
                <Cell
                  key={data['IndexSummaries.farmName']}
                  fill={EMPHASIS_COLOR}
                  stroke="#FFF"
                  strokeWidth="5"
                />
              );
            }
            return (
              <Cell key={data['IndexSummaries.farmName']} fill={getColor(index)} stroke="white" />
            );
          })}
        </Pie>
        <Legend verticalAlign="top" />
        <Tooltip formatter={format} labelFormatter={format} />
      </PieChart>
    </ResponsiveContainer>
  ),
  number: ({ resultSet }) => (
    <>
      {resultSet.seriesNames().map(({ key }) => (
        <h4 key={key} className="p-text-center">
          {format(resultSet.totalRow()[key])}
        </h4>
      ))}
    </>
  ),
  table: ({ resultSet, pivotConfig }) => (
    <DataTable value={resultSet.tablePivot(pivotConfig)} className="p-datatable-striped">
      {resultSet.tableColumns(pivotConfig).map(({ title, key }) => (
        <Column
          key={key}
          header={title}
          body={(v) => (
            <span
              // eslint-disable-next-line max-len
              style={{ color: v['IndexSummaries.farmName'].length === LENGTH_TO_EMPHASIS ? 'inheritance' : EMPHASIS_COLOR }}
            >
              {format(v[key])}
            </span>
          )}
        />
      ))}
    </DataTable>
  ),
};

const TypeToMemoChartComponent = (
  Object.keys(TypeToChartComponent) as ChartType[]
).reduce((acc, key) => {
  acc[key] = React.memo(TypeToChartComponent[key]);
  return acc;
}, {} as Record<ChartType, React.FC<ChartPropsTypes>>);

type RenderChartType = (Component: React.FC<ChartPropsTypes>) => (
  (arg: {
    resultSet: ChartPropsTypes['resultSet'] | null,
    pivotConfig: ChartPropsTypes['pivotConfig'],
    extraVizState: ChartPropsTypes['extraVizState'],
    error: Error | null,
  }) => React.ReactElement
)

const renderChart: RenderChartType = (Component) => ({
  resultSet,
  error,
  pivotConfig,
  extraVizState,
}) => {
  if (resultSet && pivotConfig) {
    return (
      <Component resultSet={resultSet} pivotConfig={pivotConfig} extraVizState={extraVizState} />
    );
  }

  if (error) return <>{error.message}</>;

  return <div className="spinner-container"> <ProgressSpinner /> </div>;
};

const ChartRenderer: React.FC<ChartRendererProps> = ({ vizState, extraVizState }) => {
  const { query, chartType, pivotConfig } = vizState;
  const {
    currentHarvest,
    currentCity,
    currentRegion,
    currentCrop,
  } = useSelector(selectBoardFiltersState);

  const queryVariables = useMemo(() => ({
    $CUSTOMERS: '',
    $SAFRA: currentHarvest,
    $CIDADE: currentCity,
    $REGIAO: currentRegion,
    $ATIVIDADE: currentCrop,
  }), [currentHarvest, currentRegion, currentCity, currentCrop]);

  const renderProps = useCustomizedCubeQuery(
    useMemo(() => query || {}, [query]),
    queryVariables,
  );

  if (!chartType || !query || !pivotConfig) return null;

  return renderChart(TypeToMemoChartComponent[chartType])(
    {
      pivotConfig,
      error: renderProps.error,
      resultSet: renderProps.resultSet,
      extraVizState: extraVizState || {},
    },
  );
};

export default ChartRenderer;

interface ChartRendererProps {
  vizState: VizState,
  extraVizState?: ExtraVizState
}
