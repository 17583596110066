import { LocalStorage } from '@agro1desenvolvimento/utils-js';

const localStorage = new LocalStorage<LocalStorageItems>();

export type LocalStorageItems = {
  'agroanalisa::currentHarvest': string,
  'agroanalisa::currentCity': string,
  'agroanalisa::currentRegion': string,
  'agroanalisa::currentCrop': string,
}

export const clearLocalStorageFilters = () => {
  localStorage.getAllKeys().forEach((key) => {
    if (key.startsWith('agroanalisa::current')) {
      localStorage.removeItem(key);
    }
  });
};

export default localStorage;
